import { mapGetters } from 'vuex'

export default {
  created() {
    if (this.teacherList === null) {
      this.$Loading.start()
      this.$store.dispatch('teacher/getTeacherList').finally(() => {
        this.$Loading.finish()
      })
    }
  },
  computed: {
    ...mapGetters({
      teacherList: 'teacher/teacherList'
    })
  }
}
