<template>
  <el-dialog
    :visible="visible"
    :title="isAdd ? '新增直播小节' : '修改直播小节'"
    class="yt-dialog yt-dialog-default section-modal"
    @close="close"
    :close-on-click-modal="false"
  >
    <!--直播表单-->
    <Form ref="liveForm" :model="liveForm" :rules="liveRuleValidate" :label-width="80">
      <FormItem label="直播名称" prop="name">
        <Input v-model="liveForm.name" placeholder="请输入直播名称" />
      </FormItem>
      <FormItem label="直播讲师" prop="teacherId">
        <AddTeacher v-model="liveForm.teacherId"></AddTeacher>
      </FormItem>
      <FormItem label="开始时间" prop="startTime">
        <DateTimePicker v-model="liveForm.startTime"></DateTimePicker>
      </FormItem>
      <FormItem label="预计时长" prop="estimateTime">
        <InputNumber :step="0.5" :min="0.5" :max="24" v-model="liveForm.estimateTime" style="font-size: 14px"></InputNumber>
        <span style="margin-left: 10px">小时</span>
      </FormItem>
      <FormItem label="直播封面" prop="cover">
        <div class="custom-upload" :class="{ uploaded: uploadSuccess }">
          <Upload
            :headers="coverType === 0 ? {} : token"
            ref="upload"
            :show-upload-list="false"
            :on-success="handleUploadCoverSuccess"
            :on-progress="handleUploadProgress"
            :before-upload="beforeUpload"
            :on-exceeded-size="handleExceededSize"
            :on-format-error="handleFormatError"
            :data="uploadParams"
            :format="['jpg', 'jpeg', 'png']"
            :max-size="10240"
            type="drag"
            :action="uploadUrl"
          >
            <Progress v-if="isUploading" :percent="percentage" hide-info></Progress>
            <img v-if="!isUploading && uploadSuccess" :src="liveForm.cover" alt="" />
            <div v-if="!isUploading && !uploadSuccess" class="upload">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-xinzeng"></use>
              </svg>
            </div>
          </Upload>
          <div class="form-tip">
            支持 jpg/gif/png 格式，不超过 10M
          </div>
        </div>
      </FormItem>
      <FormItem label="直播简介" prop="description">
        <Input type="textarea" v-model="liveForm.description" placeholder="请输入直播简介" />
      </FormItem>
    </Form>

    <div class="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="dealLiveSection" :loading="loading">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import courseApi from '@api/course'
import ossApi from '@api/oss'
import DateTimePicker from '@components/common/DateTimePicker'
import AddTeacher from '@components/common/AddTeacher'
import sectionTypes from '@/util/sectionType'
import config from '@/config/config'

export default {
  components: {
    DateTimePicker,
    AddTeacher
  },
  props: {
    coverType: {
      type: Number,
      default: 1
    },
    value: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    //修改小节时小节本身
    section: {
      type: Object
    },
    courseId: {
      type: Number,
      required: true
    },
    //添加小节时需要的章节 id
    chapterId: {
      type: Number,
      default: -1
    },
    //添加小节时小节位置，已有小节数量加 1
    sequence: {
      type: Number
    }
  },
  data() {
    return {
      token: {
        Authorization: 'Bearer ' + (localStorage.getItem('auth') && JSON.parse(localStorage.getItem('auth')).access_token)
      },
      visible: this.value,
      loading: false,
      isUploading: false,
      percentage: 0,
      uploadUrl: config.baseUrl + '/file/api/v1/upload/cover',
      uploadSuccess: false,
      uploadParams: {
        key: '',
        policy: '',
        OSSAccessKeyId: '',
        success_action_status: '200',
        Signature: ''
      },
      liveForm: {
        name: this.isAdd ? '' : this.section.name,
        teacherId: this.isAdd ? '' : this.section.teacherId,
        startTime: this.isAdd ? null : this.section.startTime,
        estimateTime: this.isAdd ? 0.5 : this.section.estimateTime,
        description: this.isAdd ? '' : this.section.description,
        cover: this.isAdd ? '' : this.section.cover
      },
      liveRuleValidate: {
        name: [
          {
            required: true,
            message: '名称不为空'
          },
          {
            type: 'string',
            max: 80,
            message: '名称不超过 80 个字'
          }
        ],
        description: [
          { required: true, message: '直播描述不为空' },
          {
            type: 'string',
            message: '直播描述不超过 600 个字',
            max: 600
          }
        ],
        teacherId: {
          type: 'number',
          required: true,
          message: '请选择讲师'
        },
        startTime: {
          type: 'date',
          required: true,
          message: '请输入开始时间'
        },
        estimateTime: {
          type: 'number',
          required: true,
          message: '请输入预计时长'
        }
      }
    }
  },
  methods: {
    close() {
      this.visible = false
      this.$emit('input', false)
      this.$emit('on-cancel')
    },
    handleUploadCoverSuccess(res, file) {
      this.uploadSuccess = true
      this.isUploading = false
      if (this.coverType === 0) {
        this.liveForm.cover = `${this.uploadUrl}/${this.uploadParams.key}`
      } else {
        this.liveForm.cover = res.res
      }
    },
    beforeUpload(file) {
      if (this.coverType === 0) {
        return ossApi.getLiveCoverToken(new Date().valueOf(), file.name).then(res => {
          const data = res.res
          this.uploadUrl = `https://${data.host}`
          this.uploadParams.key = data.dir
          this.uploadParams.OSSAccessKeyId = data.accessId
          this.uploadParams.policy = data.policy
          this.uploadParams.Signature = data.signature
        })
      }
    },
    handleExceededSize() {
      this.$message.error('图片大小不超过 10M')
    },
    handleFormatError() {
      this.$message.error('仅支持上传图片')
    },
    handleUploadProgress(event, file) {
      this.isUploading = true
      this.percentage = file.percentage
    },
    reset() {
      this.isUploading = false
      this.uploadSuccess = false
      this.percentage = 0
      this.uploadUrl = ''
      this.uploadParams = {
        key: '',
        policy: '',
        OSSAccessKeyId: '',
        success_action_status: '200',
        Signature: ''
      }
    },
    dealLiveSection() {
      this.$refs.liveForm.validate(valid => {
        if (!valid) {
          return
        }

        this.loading = true
        const params = {
          title: this.liveForm.name,
          cover: this.liveForm.cover,
          teacherId: this.liveForm.teacherId,
          description: this.liveForm.description,
          startTime: this.liveForm.startTime.valueOf(),
          estimateTime: this.liveForm.estimateTime,
          sequence: this.sequence
        }

        const promise = this.isAdd
          ? courseApi.addLiveSection(
              Object.assign(
                {
                  courseId: this.courseId,
                  chapterId: this.chapterId
                },
                params
              )
            )
          : courseApi.updateLiveSection(Object.assign({ liveId: this.section.sectionId }, params))

        promise
          .then(res => {
            this.close()
            this.reset()
            this.$emit('on-add-success')
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    updateLiveForm(section) {
      if (this.isAdd || String(section.type) !== sectionTypes.LIVE.value) {
        return
      }

      this.liveForm = {
        name: section.name,
        teacherId: section.teacherId,
        cover: section.cover,
        startTime: new Date(section.startTime),
        estimateTime: section.estimateTime,
        description: section.description
      }

      if (section.cover) {
        this.uploadSuccess = true
      }
    }
  },
  watch: {
    value(val) {
      this.visible = val
      if (val && this.isAdd) {
        this.$refs.liveForm.resetFields()
      }
    },
    isAdd(val) {
      // 编辑状态和新增状态互相转换时需要重置表单
      if (val) {
        this.$refs.liveForm.resetFields()
      } else {
        this.updateLiveForm(this.section)
      }
    },
    section(val) {
      this.updateLiveForm(val)
    }
  }
}
</script>
<style lang="less" scoped>
.section-modal {
  ::v-deep .el-dialog__body {
    max-height: inherit !important;
  }
  .custom-upload {
    ::v-deep .ivu-upload-drag {
      border: 1px dashed #dcdee2;
      &:hover {
        border: 1px dashed #2598e5;
      }
    }

    .ivu-upload-drag {
      width: 124px;

      img {
        width: 124px;
        height: 90px;
      }
    }

    &.uploaded {
      .ivu-upload-drag {
        border: none;
        display: inline;

        &:hover {
          border: none;
        }
      }
    }
  }

  .upload {
    width: 124px;
    height: 90px;
    font-size: 60px;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #b8c2d1;
    }
  }
}
</style>
