<template>
  <div class="table-container" v-loading="loading" element-loading-text="加载中...">
    <Table :columns="columns" :data="tableData" @on-row-click="showDetail">
      <template slot-scope="{ row, index }" slot="paperName">
        <img src="@/assets/209.png" height="16" width="14" style="position: relative;top: 3px; right: 3px" />
        <span style="line-height: normal">{{ row.paperName }}</span>
      </template>
      <template slot-scope="{ row, index }" slot="createdTime">
        <span>{{ $formatTime(row.createdTime, 'yyyy-MM-dd hh:mm') }}</span>
      </template>
      <template v-if="isOwner" slot-scope="{ row, index }" slot="op">
        <Icon
          @click.stop="
            questionnaireId = row.questionnaireId
            deleteVisible = true
          "
          type="ios-trash-outline"
          style="font-size: 24px;cursor: pointer"
        />
      </template>
    </Table>
    <div v-if="isOwner && tableData.length === 0" class="chapter-add" @click.stop="addModal = true">
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#icon-xinzeng" />
      </svg>
      新增问卷
    </div>
    <DeleteModal v-model="deleteVisible" :title="'确认删除问卷'" :loading="deleteLoading" @on-ok="delQuestionnaire">
      <span>删除问卷后不可恢复，你还要继续吗？</span>
    </DeleteModal>
    <Modal v-model="addModal" @on-cancel="cancel" :width="1080">
      <div slot="header" style="font-size: 20px;color: #3A4E64;text-align: center">
        <span>选择问卷</span>
        <p>- 从以下选择评价问卷 -</p>
      </div>
      <div>
        <div class="selectTable">
          <div class="top">
            <div>
              <el-select v-model="paperId" style="width:200px" placeholder="请选择" @change="selectBank">
                <el-option v-for="item in testPapers" :key="item.paperBankId" :label="item.name" :value="item.paperBankId"> </el-option>
              </el-select>
              <el-input
                v-model="keyword"
                @keyup.enter.native="search"
                class="set_suffix"
                size="small"
                placeholder="请输入查找信息"
                style="width: 200px"
              >
                <template slot="suffix">
                  <YTIcon href="#icon-sousuo" style="fill: #A3A5B0" @click="search" />
                </template>
              </el-input>
            </div>
          </div>
          <Table
            highlight-row
            @on-current-change="selectPaper"
            :height="tableH - 90"
            :data="paperData"
            :columns="paperColumns"
            :loading="paperLoading"
            style="position: relative"
            stripe
          >
            <template slot-scope="{ row }" slot="radio">
              <Radio :value="row.paperId === selectPaperId" />
            </template>
            <template slot-scope="{ row }" slot="level">
              <span>{{ checkLevel(row.level) }}</span>
            </template>
            <template slot-scope="{ row, index }" slot="cTime">
              <span>{{ $formatTime(row.createTime, 'yyyy-MM-dd hh:mm') }}</span>
            </template>
            <template slot-scope="{ row, index }" slot="uTime">
              <span>{{ $formatTime(row.updateTime, 'yyyy-MM-dd hh:mm') }}</span>
            </template>
          </Table>
          <div v-if="!paperData.length" class="no-hint">
            <div>
              <YTIcon :href="'#icon-zanwushuju'" slot="reference" style="width:120px;height:124px;"></YTIcon>
              <p>暂无数据</p>
            </div>
          </div>
        </div>
        <Page
          class="yt-page"
          :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
          :total="total"
          :current="page + 1"
          :page-size-opts="[5, 10, 20, 50]"
          :page-size="size"
          show-elevator
          show-sizer
          show-total
          @on-change="handePageChange"
          @on-page-size-change="handleSizeChange"
        />
      </div>
      <div slot="footer" style="display: flex;justify-content: center;">
        <div style="margin-left: 30px">
          <el-button size="small" @click="cancel">取消</el-button>
          <el-button size="small" type="primary" @click="confirm" :loading="confirmLoading">确定</el-button>
          <!--        </div>-->
        </div>
      </div>
    </Modal>
    <Modal v-model="detailModal" :title="questionnaireName">
      <div style="overflow: auto;height: 600px">
        <div v-for="(item, index) in detailData" :key="index" style="margin: 12px 0">
          <h3
            style="margin-left: 30px"
            v-html="index + 1 + '.' + '【' + getType(item.questionDetail.questionType) + '】' + item.questionDetail.stem.replace(/<\/?p[^>]*>/gi, '')"
          ></h3>
          <div v-if="item.questionDetail.questionType === 1 || item.questionDetail.questionType === 2">
            <div v-for="(option, i) in item.questionDetail.options" :key="i" style="margin: 8px 10px;display: flex;">
              <div style="width: 30px">
                <div v-if="option.selectedCount !== undefined">({{ option.selectedCount }}次)</div>
              </div>

              <div style="display: flex;">
                <div>{{ String.fromCharCode(i + 65) }}.&nbsp;</div>
                <div v-html="option.content.replace(/<\/?p[^>]*>/gi, '').replace(/\<img src=/gi, replaceImg)"></div>
              </div>
            </div>
          </div>
          <div v-if="item.questionDetail.questionType === 0" style="margin-left: 30px">
            <div style="margin: 15px 0">
              <Radio disabled>正确</Radio>
              <Radio disabled>错误</Radio>
            </div>
          </div>
          <div v-for="(answer, index) in questionnaireAnswers" :key="index" v-if="item.questionDetail.questionType === 4" style="margin: 12px 30px">
            <card>{{ answer }}</card>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="detailModal = false">取消</el-button>
        <el-button type="primary">确定</el-button>
      </div>
    </Modal>
  </div>
</template>

<script>
import DeleteModal from '@components/common/DeleteModal'
import paper from '@api/paper'
import { difficulty } from '@util/difficulty'
import exam from '@api/exam'
import YTIcon from '@components/common/YTIcon'

export default {
  name: 'CourseQuestionnaire',
  components: { DeleteModal, YTIcon },
  props: {
    isOwner: {
      type: Number
    },
    courseId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      replaceImg: '<br /><img style="width:300px;height:150px" src=',
      questionnaireName: '',
      questionnaireId: '',
      paperLoading: false,
      paperData: [],
      tableH: document.body.clientHeight - 204,
      keyword: '',
      selectPaperId: '',
      paperId: '',
      testPapers: [],
      confirmLoading: false,
      addModal: false,
      loading: false,
      deleteVisible: false,
      deleteLoading: false,
      paperColumns: [
        {
          title: ' ',
          slot: 'radio',
          width: 60,
          align: 'center'
        },
        {
          title: '问卷名',
          key: 'name'
        },
        {
          title: '总分',
          key: 'totalPoints'
        },
        {
          title: '题量',
          key: 'questionAmount'
        },
        {
          title: '难度',
          slot: 'level'
        },
        {
          title: '创建时间',
          slot: 'cTime'
        },
        {
          title: '修改时间',
          slot: 'uTime'
        },
        {
          title: '创建人',
          key: 'creatorName'
        }
      ],
      columns: [
        {
          title: '问卷名称',
          slot: 'paperName',
          className: 'name-column'
        },
        {
          title: '反馈次数',
          key: 'submitCount',
          className: 'other-column'
        },
        {
          title: '上传时间',
          slot: 'createdTime',
          className: 'other-column'
        },
        {
          title: '上传者',
          key: 'creatorName',
          className: 'other-column'
        },
        {
          title: '操作',
          slot: 'op',
          className: 'other-column'
        }
      ],
      tableData: [],
      detailModal: false,
      detailData: [],
      questionnaireAnswers: [],
      page: 0,
      size: 10,
      total: 0
    }
  },
  created() {
    paper.getAllPaperBanks().then(res => {
      this.testPapers = res.res
    })
    this.getQuestionnaire()
  },
  methods: {
    handleSizeChange(size) {
      this.page = 0
      this.size = size
      this.getPapers(this.paperId)
    },
    handePageChange(page) {
      this.page = page - 1
      this.getPapers(this.paperId)
    },
    getChoiceLetter(index) {
      return String.fromCharCode(index + 65)
    },
    getType(type) {
      switch (type) {
        case 0:
          return '判断题'
        case 1:
          return '单选题'
        case 2:
          return '多选题'
        case 3:
          return '填空题'
        case 4:
          return '解答题'
        case 5:
          return '代码题'
      }
    },
    showDetail(data) {
      this.questionnaireName = data.paperName
      this.questionnaireId = data.questionnaireId
      this.detailModal = true
      this.detailData = []
      exam.getQuestionnaireDetail(this.questionnaireId).then(res => {
        res.res.sectionVOS.forEach(item => {
          this.detailData.push(...item.questionVOS)
          item.questionVOS.map(i => {
            if (i.questionDetail.questionType === 4) {
              this.questionnaireAnswers = i.questionDetail.questionnaireAnswers
            }
          })
        })
      })
    },
    delQuestionnaire() {
      this.deleteLoading = true
      exam
        .delQuestionnaire(this.questionnaireId)
        .then(res => {
          this.getQuestionnaire()
          this.deleteLoading = false
          this.$message.success('删除成功')
          this.deleteVisible = false
        })
        .catch(() => {
          this.deleteLoading = false
        })
    },
    getQuestionnaire() {
      this.loading = true
      exam
        .getQuestionnaire(this.courseId)
        .then(res => {
          if (JSON.stringify(res.res) !== '{}') {
            this.tableData = [res.res]
          } else {
            this.tableData = []
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    confirm() {
      if (this.selectPaperId === '') {
        this.$message.error('请选择问卷')
        return
      } else {
        this.confirmLoading = true
        exam
          .addQuestionnaire(this.courseId, this.selectPaperId)
          .then(res => {
            this.$message.success('添加成功')
            this.getQuestionnaire()
            this.cancel()
          })
          .catch(() => {
            this.confirmLoading = false
          })
      }
    },
    selectBank(val) {
      if (val) {
        this.paperId = val
        this.keyword = ''
        this.getPapers(val)
      }
    },
    getPapers(id) {
      this.paperLoading = true
      paper
        .getAllPaperList(id, this.page, this.size)
        .then(res => {
          this.paperData = res.res.data
          this.total = res.res.total
          this.paperLoading = false
        })
        .catch(() => {
          this.paperLoading = false
        })
    },
    selectPaper(data) {
      this.selectPaperId = data.paperId
    },
    checkLevel(num) {
      return difficulty[num].label
    },
    search() {
      if (this.paperId === '') {
        this.$message.warning('请选择问卷库')
        return
      }
      this.paperLoading = true
      paper
        .searchPaper(0, 10000, {
          paperBankId: this.paperId,
          name: this.keyword
        })
        .then(res => {
          this.paperData = res.res.data
          this.paperLoading = false
        })
        .catch(() => {
          this.paperLoading = false
        })
    },
    cancel() {
      this.addModal = false
      this.paperData = []
      this.paperId = ''
      this.keyword = ''
      this.selectPaperId = ''
      this.confirmLoading = false
    }
  }
}
</script>

<style scoped lang="less">
.table-container {
  ::v-deep .flex-table {
    .name-column {
      flex: auto;
    }
    .other-column {
      width: 20%;
    }
    .ivu-table-tip {
      display: none;
    }
  }
}
.selectTable {
  .top {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    .search {
      width: 300px;
      ::v-deep .ivu-input {
        border: 0;
        border-bottom: 1px solid #ccc;
        border-radius: 0;

        &:focus {
          box-shadow: 0 1px 0 rgba(45, 140, 240, 0.2);
        }
      }
    }
  }
}
/*滚动条的宽度*/
::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
/*外层轨道。可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果*/
::-webkit-scrollbar-track {
  width: 6px;
  background-color: #dcdcdc;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
  display: none;
}
/*滚动条的设置*/
::-webkit-scrollbar-thumb {
  background-color: #323a4b;
  background-clip: padding-box;
  min-height: 28px;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
::v-deep .ivu-modal .ivu-modal-body {
  padding: 20px 17px;
}
::v-deep .ivu-table-header {
  th {
    span {
      font-size: 12px !important;
      font-weight: bold;
      line-height: 17px;
      color: #000000;
    }
  }
}
.chapter-add {
  text-align: center;
  padding: 8px 0;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #999999;
  border: 1px solid #d9d9d9;
  border-style: dashed;
  border-radius: 4px;
  .icon {
    color: #999999;
  }
}
::v-deep .ivu-table-tip {
  display: none;
}
::v-deep .ivu-modal {
  .ivu-modal-header {
    padding: 30px 16px 20px 16px;
    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      color: #999999;
      margin-top: 12px;
    }
    span {
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      color: #282c3d;
    }
  }
  .ivu-modal-body {
    padding: 0 20px 20px;
  }
}
::v-deep .el-select {
  width: 200px;
  height: 32px;
  margin-right: 12px;
  .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
  .el-input__icon {
    line-height: 32px;
  }
}
::v-deep .el-input {
  .el-input__suffix {
    line-height: 32px;
  }
}
.no-hint {
  position: absolute;
  top: 45%;
  left: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    text-align: center;
  }
}
</style>
