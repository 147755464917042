<template>
  <div>
    <!--    <Upload v-if="needCover && !imageObject.base64" type="drag" class="uploader-fixed-size" :before-upload="handleBeforeImgUpload" action="">-->
    <!--      <JYIcon href="#icon-morentu" style="color: #3399ff; font-size: 42px" />-->
    <!--      <p>上传封面</p>-->
    <!--    </Upload>-->
    <!--    <img v-else :src="imageObject.base64" alt="" class="uploader-fixed-size">-->
    <Upload :before-upload="handleBeforeUpload" action="" type="drag" class="uploader-fixed-size">
      <div>
        <JYIcon href="#icon-shangchuan" style="color: #3399ff; font-size: 42px" />
        <p v-if="!isUploading">点击或拖放来上传 视频 / 封面</p>
      </div>
    </Upload>
    <div v-if="imageObject.file" style="" class="cover-item">
      <div class="left">
        <img :src="imageObject.base64" alt="" width="48" height="48" style="object-fit: cover" />
      </div>
      <div class="middle">
        <span style="font-weight: 900">{{ imageObject.name }}</span>
      </div>
      <div class="right">
        <Icon style="color: red" type="md-close-circle" size="16" />
      </div>
      <div class="progress" :style="`width: ${uploadProgress}%`" :class="{ finish: isUploadFinished }">
        <span class="progress-text">{{ uploadProgress }}%</span>
      </div>
    </div>
    <div v-if="videoObject.file" style="" class="cover-item">
      <div class="left">
        <JYIcon href="#icon-shipin2" style="width: 36px; height: 36px" />
      </div>
      <div class="middle">
        <span style="font-weight: 900">{{ videoObject.name }}</span>
      </div>
      <div class="right">
        <Icon style="color: red" type="md-close-circle" size="16" />
      </div>
      <div class="progress" :style="`width: ${uploadProgress}%`" :class="{ finish: isUploadFinished }">
        <span class="progress-text">{{ uploadProgress }}%</span>
      </div>
    </div>
    <div class="upload-footer">
      <Button :disabled="!readyToUpload" v-if="!isUploading && !isUploadFinished" @click="handleUpload" size="small" type="primary">确认上传</Button>
      <Button :disabled="!readyToUpload" v-if="isUploading" :loading="true" size="small" type="primary">上传中</Button>
      <Button :disabled="!readyToUpload" v-if="isUploadFinished" @click="handleUpload" size="small" type="primary">重新上传</Button>
    </div>
    <!--    <span v-if="isUploadFinished" style="color: green"><JYIcon href="#icon-gouxuan"/> 上传完成</span>-->
  </div>
</template>

<script>
import TcVod from 'vod-js-sdk-v6'
import VideoAPI from '@api/video'
import JYIcon from '@components/common/JYIcon'
import JYProgress from '@components/common/JYProgress'
export default {
  name: 'VideoUploader',
  props: {
    needCover: {
      type: Boolean,
      default: false
    }
  },
  async created() {
    this.tcVod = new TcVod({
      getSignature: VideoAPI.getVodUploadSignature
    })
  },
  data() {
    return {
      isUploading: false,
      isUploadFinished: false,
      uploadProgress: 0,
      uploadedList: [],
      // imageUrl: '',
      imageObject: {
        name: '',
        url: '',
        base64: '',
        file: null
      },
      videoObject: {
        name: '',
        targetId: null,
        url: '',
        size: 0,
        file: null
      },
      supportVideoTypeMap: [
        // 'video/x-flv',
        'video/mp4'
        // 'video/x-msvideo',
        // 'video/x-ms-wmv'
      ],
      supportImageTypeMap: ['image/jpeg', 'image/jpg', 'image/png']
    }
  },
  computed: {
    readyToUpload() {
      return this.videoObject.file
    }
  },
  methods: {
    handleBeforeUpload(file) {
      this.reset()
      if (this._isVideoType(file.type)) {
        this.videoObject = {
          name: file.name,
          size: file.size,
          file: file
        }
      }
      if (this._isImageType(file.type)) {
        const reader = new FileReader()
        reader.onload = ({ target }) => {
          const base64 = target.result
          this.imageObject.base64 = base64
        }
        reader.readAsDataURL(file)
        this.imageObject.file = file
        this.imageObject.name = file.name
      }
      if (file.type.indexOf('video') !== -1 && !this._isVideoType(file.type)) {
        this.$message.error('支持的视频文件格式：' + this.supportVideoTypeMap.join(', '))
      }
      if (file.type.indexOf('image') !== -1 && !this._isImageType(file.type)) {
        this.$message.error('支持的封面格式：' + this.supportImageTypeMap.join(', '))
      }
      return false
    },
    _isVideoType(mimeString) {
      return this.supportVideoTypeMap.indexOf(mimeString) !== -1
    },
    _isImageType(mimeString) {
      return this.supportImageTypeMap.indexOf(mimeString) !== -1
    },
    handleUpload() {
      const payload = {
        videoFile: this.videoObject.file,
        coverFile: this.imageObject.file
      }
      this.isUploading = true
      const uploader = this.tcVod.upload(payload)
      uploader.on('video_progress', info => {
        let progress = Math.floor(info.percent * 100)
        if (progress === 1 && !this.isUploadFinished) {
          progress = 0.99
        }
        this.uploadProgress = progress
      })
      return uploader
        .done()
        .then(data => {
          this.videoObject.url = data.video.url
          this.videoObject.targetId = data.fileId
          this.imageObject.url = data.cover.url
          const retData = {
            cover: { ...this.imageObject },
            video: { ...this.videoObject }
          }
          // this.$emit('on-finish', retData)
        })
        .finally(() => {
          this.isUploading = false
          this.isUploadFinished = true
        })
    },
    reset() {
      this.isUploadFinished = false
      this.isUploading = false
      this.uploadProgress = 0
    }
  },
  components: {
    JYIcon,
    JYProgress
  }
}
</script>

<style lang="less" scoped>
.video-uploader-layout {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.cover-item {
  height: 50px;
  display: flex;
  position: relative;
  align-items: center;
  text-align: left;
  border-top: solid 1px #e3e3e3;
  border-bottom: solid 1px #e3e3e3;
  overflow: hidden;

  & .left {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
  & .middle {
    flex: 1;
    padding-left: 5px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 10;
  }
  & .right {
    width: 24px;
    z-index: 10;
  }

  & .progress {
    position: absolute;
    height: 100%;
    width: 40%;
    background: rgba(104, 125, 219, 0.08);
    transition: 0.3s ease;
    .progress-text {
      position: absolute;
      height: 48px;
      line-height: 48px;
      color: rgba(151, 159, 184, 0.26);
      font-size: 40px;
      right: 8px;
    }
  }
  & .progress.finish {
    background: #47c7654f;
  }
}
.cover-item + .cover-item {
  border-top: none;
}
.uploaded-delete {
  position: absolute;
  right: 4px;
  top: 4px;
}
.uploaded-delete:hover {
  color: #6c91da;
}
.uploader-fixed-size {
  margin-bottom: 10px;
}
.upload-footer {
  margin: 8px 0;
  text-align: center;
}
</style>
