export const PRACTICE_STATUS = {
  EDIT: {
    value: 1,
    label: '编辑中',
    color: '#B8C2D1FF'
  },
  PUBLISH: {
    value: 2,
    label: '已发布',
    color: '#75C979FF'
  },
  CLOSE: {
    value: 3,
    label: '已关闭',
    color: '#D55959FF'
  }
}

/**
 * 获取练习状态中文描述，默认"编辑中"
 * @param value
 * @returns {*}
 */
export const getStatusByValue = value => {
  const statusList = Object.keys(PRACTICE_STATUS)
  for (const status of statusList) {
    if (PRACTICE_STATUS[status].value === value) {
      return PRACTICE_STATUS[status]
    }
  }
  return PRACTICE_STATUS.EDIT
}
