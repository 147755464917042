<template>
  <el-dialog
    :visible="visible"
    :title="isAdd ? '新增资料小节' : '修改资料小节'"
    class="yt-dialog yt-dialog-default section-modal"
    @close="close"
    :close-on-click-modal="false"
  >
    <!--资料表单-->
    <Form ref="datumForm" :model="datumForm" :rules="datumRuleValidate" :label-width="80">
      <FormItem label="资料名称" prop="name">
        <Input v-model="datumForm.name" placeholder="请输入资料名称" />
        <div class="form-tip" v-if="!datumRuleValidate.name.error">
          不超过 60 个字
        </div>
      </FormItem>
      <FormItem label="资料" prop="fileName">
        <Upload
          :headers="coverType === 0 ? {} : token"
          ref="upload"
          :show-upload-list="true"
          :on-success="handleUploadFileSuccess"
          :on-progress="handleUploadProgress"
          :before-upload="beforeUpload"
          :on-exceeded-size="handleExceededSize"
          :data="uploadParams"
          :max-size="1024 * 500"
          type="drag"
          :action="uploadUrl"
        >
          <div class="upload">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shangchuan" />
            </svg>
            上传文件
          </div>
        </Upload>
        <div v-if="file !== null && datumForm.url !== ''">{{ file.name }}</div>
      </FormItem>
      <FormItem label="或者">
        <Input v-model="fileUrl" placeholder="请输入资料链接" />
      </FormItem>
    </Form>

    <div class="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="addDatumSection" :loading="loading">确定 </el-button>
    </div>
  </el-dialog>
</template>
<script>
import courseApi from '@api/course'
import ossApi from '@api/oss'
import { getFileType } from '@/util/fileType'
import config from '@/config/config'
import sectionTypes from '@/util/sectionType'
export default {
  props: {
    coverType: {
      type: Number,
      default: 1
    },
    value: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    //修改小节时小节本身
    section: {
      type: Object
    },
    courseId: {
      type: Number,
      required: true
    },
    //课程的根目录
    folderId: {
      type: Number,
      required: true
    },
    //添加小节时需要的章节 id
    chapterId: {
      type: Number,
      default: -1
    },
    //添加小节时小节位置，已有小节数量加 1
    sequence: {
      type: Number
    }
  },
  data: function() {
    const that = this
    return {
      token: {
        Authorization: 'Bearer ' + (localStorage.getItem('auth') && JSON.parse(localStorage.getItem('auth')).access_token)
      },
      fileUrl: '',
      visible: this.value,
      loading: false,
      serveLoca: '',
      isUploading: false,
      percentage: 0,
      uploadUrl: config.baseUrl + '/file/api/v1/upload/course/file',
      uploadSuccess: false,
      uploadParams: {
        key: '',
        policy: '',
        OSSAccessKeyId: '',
        success_action_status: '200',
        Signature: '',
        ContentDisposition: 'attachment; filename=\\"5.txt\\"; filename*=utf-8\'\'5.txt'
      },
      file: null,
      datumForm: {
        name: '',
        fileName: '',
        size: 0,
        fileType: 0,
        url: ''
      },
      datumRuleValidate: {
        name: {
          required: true,
          error: false,
          validator: (rule, value, callback) => {
            if (value.trim() === '') {
              that.datumRuleValidate.name.error = true
              callback(new Error('资料名称不为空'))
            } else if (value.length > 80) {
              that.datumRuleValidate.name.error = true
              callback(new Error('资料名称不超过 60 个字'))
            } else {
              that.datumRuleValidate.name.error = false
              callback()
            }
          }
        },
        fileName: {
          type: 'string',
          error: false,
          required: true,
          validator: (rule, value, callback) => {
            if (this.fileUrl === null && value.trim() === '') {
              that.datumRuleValidate.fileName.error = true
              callback(new Error('资料文件和文件链接任意上传其一，两者不能都为空'))
            } else {
              that.datumRuleValidate.fileName.error = false
              callback()
            }
          }
        }
      }
    }
  },
  methods: {
    open(data) {
      this.visible = true
      this.datumForm.name = this.isAdd ? '' : data.name
      this.fileUrl = data.datumUrl
    },
    close() {
      this.visible = false
      this.$emit('input', false)
      this.$emit('on-cancel')
      this.resetForm()
    },
    handleUploadFileSuccess(res, file) {
      this.uploadSuccess = true
      this.isUploading = false
      if (window.uploadUrl) {
        this.datumForm.url = this.serveLoca
        this.fileUrl = this.serveLoca
      } else {
        this.datumForm.url = `${this.uploadUrl}/${this.uploadParams.key}`
        this.fileUrl = `${this.uploadUrl}/${this.uploadParams.key}`
      }
      // if (this.coverType === 0) {
      //   this.datumForm.url = `${this.uploadUrl}/${this.uploadParams.key}`
      // } else {
      //   this.datumForm.url = res.res
      // }
      this.datumForm.fileName = this.file.name
    },
    beforeUpload(file) {
      this.file = file
      const encodeFileName = encodeURI((file.name || '').replace(';', '').replace(',', ''))
      this.uploadParams.ContentDisposition = `attachment;filename="${encodeFileName}";filename*=utf-8''${encodeFileName}`
      this.datumForm.size = file.size
      this.datumForm.fileType = getFileType(file.name)
      //清除已上传的文件
      if (window.uploadUrl) {
        let forData = new FormData()
        forData.append('file', file)
        ossApi.upFile(forData).then(res => {
          this.serveLoca = res.res
          this.handleUploadFileSuccess()
        })
      } else {
        return ossApi.getFileToken(this.folderId, file.name).then(res => {
          const data = res.res
          this.uploadUrl = `https://${data.host}`
          this.uploadParams.key = data.dir
          this.uploadParams.OSSAccessKeyId = data.accessId
          this.uploadParams.policy = data.policy
          this.uploadParams.Signature = data.signature
          let formData = new FormData()
          formData.append('key', this.uploadParams.key)
          formData.append('OSSAccessKeyId', this.uploadParams.OSSAccessKeyId)
          formData.append('policy', this.uploadParams.policy)
          formData.append('Signature', this.uploadParams.Signature)
          formData.append('file', file)
          fetch(`https://${data.host}`, {
            method: 'POST',
            body: formData
          }).then(data => {
            this.handleUploadFileSuccess()
          })
          // 中文名文件需要参照 RFC 6266 , 使用encode并设置字符集的Content-Disposition
          this.$refs.upload.fileList.splice(0, 1)
        })
        // } else {
        //   this.$refs.upload.fileList.splice(0, 1)
        // }
      }
      return false
    },
    handleExceededSize() {
      this.$message.error('文件大小不超过 500M')
    },
    handleUploadProgress(event, file) {
      this.isUploading = true
      this.percentage = file.percentage
    },
    // message(msg, type) {
    //   this.$message({ message: msg, type: type })
    // },
    addDatumSection() {
      // if (this.datumForm.fileName === '') {
      //   return this.message('请上传文件', 'warning')
      // }
      this.$refs.datumForm.validate(valid => {
        if (!valid) {
          return
        }
        this.loading = true
        if (this.isAdd) {
          let payload = {
            courseId: this.courseId,
            chapterId: this.chapterId,
            title: this.datumForm.name,
            fileName: this.fileUrl ? this.datumForm.name : this.datumForm.fileName,
            url: this.fileUrl ? this.fileUrl : this.datumForm.url,
            size: this.datumForm.size,
            fileType: this.fileUrl ? 8 : this.datumForm.fileType,
            sequence: this.sequence
          }
          courseApi
            .addDatumSection(payload)
            .then(() => {
              this.close()
              this.$emit('on-add-success')
            })
            .finally(() => {
              this.loading = false
              this.resetForm()
            })
        } else {
          courseApi
            .updateDatumSection({
              sequence: this.section.sequence,
              title: this.datumForm.name,
              url: this.fileUrl,
              fileName: this.fileUrl ? this.datumForm.name : this.datumForm.fileName,
              size: this.datumForm.size,
              fileType: this.fileUrl ? 8 : this.datumForm.fileType,
              datumId: this.section.sectionId
            })
            .then(() => {
              this.visible = false
              this.$emit('input', false)
              this.$emit('on-add-success')
            })
            .finally(() => {
              this.loading = false
              this.resetForm()
            })
        }
      })
    },
    resetForm() {
      this.file = null
      this.fileUrl = null
      this.datumForm.name = ''
      this.$refs.upload.clearFiles()
    }
  },
  watch: {
    value(val) {
      this.visible = val
      if (val && this.isAdd) {
        this.$refs.datumForm.resetFields()
        this.$refs.upload.fileList = []
      }
    },
    isAdd(val) {
      // 编辑状态和新增状态互相转换时需要重置表单
      if (val) {
        this.$refs.datumForm.resetFields()
      } else {
        if (this.isAdd || String(this.section.type) !== sectionTypes.DATUM.value) {
          return
        }

        this.datumForm = {
          name: this.section.name,
          fileName: '',
          size: 0,
          fileType: 0,
          url: ''
        }
      }
    }
  },
  computed: {
    ossParams() {
      const params = Object.assign({}, this.uploadParams)
      params['Content-Disposition'] = params.ContentDisposition
      delete params.ContentDisposition
      return params
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .ivu-upload-drag {
  border: 1px dashed #dcdee2;
  &:hover {
    border: 1px dashed #2598e5;
  }
}
.upload {
  text-align: left;
}
.section-modal {
  ::v-deep .el-dialog__body {
    max-height: inherit !important;
  }
}
</style>
