<template>
  <div class="course-container yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>课程管理</el-breadcrumb-item>
        <el-breadcrumb-item><span @click="toCourse" class="toCourse">课程列表</span></el-breadcrumb-item>
        <el-breadcrumb-item>课程详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-container">
      <div class="content">
        <!--课程详情-->
        <div class="detail" v-loading="loading" element-loading-text="加载中...">
          <!--课程封面-->
          <div style="display: flex;align-items: center" @mouseenter="hoverAvatar = true" @mouseleave="hoverAvatar = false">
            <div style="position: relative; margin: 20px 0 20px 20px">
              <div>
                <img :src="course.cover" alt="" style="border-radius: 3px" />
              </div>
              <div class="detailCover" v-if="course.isOwner && hoverAvatar" style="border-radius: 3px">
                <Upload
                  :show-upload-list="false"
                  :before-upload="beforeUpload"
                  :data="uploadParams"
                  :format="['jpg', 'jpeg', 'png']"
                  type="drag"
                  action="#"
                >
                  <div style="height: 200px;color: white;font-weight: bold;font-size: 16px;line-height: 200px;">
                    编辑
                  </div>
                </Upload>
              </div>
            </div>
          </div>
          <!--右侧课程详情-->
          <div class="right-detail" style="margin-left: 20px">
            <div class="detail-item">
              <UpdateInput
                :updateData="course.name"
                @setValue="updateClassName($event, 'name')"
                :maxLength="30"
                style="width: auto"
                ref="skillUpdateRateNameInput"
              >
                <p @click="nameUpdate('name')">
                  {{ course.name === null || course.name === '' ? '暂无名称' : course.name }}
                </p>
              </UpdateInput>
            </div>
            <div class="detail-item">
              课程描述：
              <UpdateInput
                :updateData="course.description"
                type="textarea"
                @setValue="updateClassName($event, 'description')"
                :maxLength="200"
                style="width: 500px"
                ref="skillUpdateDescriptionInput"
              >
                <span @click="nameUpdate('description')">
                  {{ course.description === null || course.description === '' ? '暂无名称' : course.description }}
                </span>
              </UpdateInput>
            </div>
            <div class="detail-item">
              岗位类型：<Tag v-for="(item, index) in course.postNameVoS" :key="index">{{ item.name }}</Tag>
            </div>
            <div class="detail-item">
              绑定目标:
              <div style="display: flex;align-items: center;flex-wrap: wrap">
                <div class="addListName" v-for="addList in targetLists" :key="addList.id">
                  <span style="white-space: nowrap">{{ addList.name }}</span>
                  <div
                    style="display: flex;align-items: center;justify-content: center;margin-left: 5px"
                    @mouseenter="showDele(addList)"
                    @click="deltarget(addList)"
                    @mouseleave="showDel = false"
                  >
                    <div class="del">
                      <i class="el-icon-close"></i>
                    </div>
                  </div>
                </div>
                <svg class="icon" aria-hidden="true" @click="bindTarget">
                  <use xlink:href="#icon-xinzeng" />
                </svg>
              </div>
            </div>
            <div class="detail-item">
              <div class="info">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-chakan1" />
                </svg>
                {{ course.students ? course.students.length : 0 }}
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-quxiaoshoucang" />
                </svg>
                {{ course.favoriteTimes }}
              </div>
            </div>
          </div>
        </div>
        <div style="padding:0 20px">
          <el-tabs v-model="currentTab" class="tabs">
            <el-tab-pane label="目录" name="0"></el-tab-pane>
            <el-tab-pane label="考试" name="1"></el-tab-pane>
            <el-tab-pane label="问卷" name="2"></el-tab-pane>
            <el-tab-pane label="评价" name="3"></el-tab-pane>
          </el-tabs>
          <component
            :is="subGroup[Number(currentTab)]"
            ref="componentList"
            :courseId="courseId"
            :isOwner="course.isOwner"
            :folder-id="this.course.folderId || 0"
          ></component>
        </div>
        <el-dialog
          :visible="addModal"
          title="添加能力"
          @close="
            addModal = false
            loading = false
            selectAbilityId = []
          "
          class="yt-dialog yt-dialog-default"
        >
          <el-cascader
            v-model="selectAbilityId"
            :options="allAbility"
            :props="{
              checkStrictly: true,
              children: 'abilities',
              label: 'name',
              value: 'id',
              expandTrigger: 'hover',
              multiple: true
            }"
            :show-all-levels="false"
            filterable
            placeholder="请选择能力"
            style="width: 100%"
            ref="cascader"
            clearable
          >
          </el-cascader>
          <div class="footer">
            <Button
              class="button"
              @click="
                addModal = false
                loading = false
                selectAbilityId = []
              "
              >取消</Button
            >
            <Button class="button" :disabled="selectAbilityId.length === 0" type="primary" :loading="loading" @click="addCourseAbility">确定</Button>
          </div>
        </el-dialog>
        <el-dialog :visible="showAddTarget" title="绑定目标" class="yt-dialog yt-dialog-medium" width="1080px" @close="cancelAdd">
          <div class="addTarget">
            <div class="left">
              <CheckboxGroup v-model="targetVal">
                <Checkbox v-for="target in targetList" :key="target.id" :label="target.id" class="target-checkbox">
                  {{ target.name }}
                </Checkbox>
              </CheckboxGroup>
            </div>
            <div class="right">
              <div class="top">
                <span>已绑定目标</span>
                <el-button type="primary" @click="$refs.deleteStargetDialog.visible = true">清空</el-button>
              </div>
              <div
                v-for="addList in targetLists"
                :key="addList.id"
                style="display: flex;align-items: center"
                @mouseenter="targetsEnter(addList)"
                @mouseleave="targetsEnterId = 0"
              >
                <div style="position: relative;left:12px;width: 8px;cursor: pointer">
                  <svg v-if="targetsEnterId === addList.id" @click="deltarget(addList)" class="icon" aria-hidden="true" font-size="8">
                    <use xlink:href="#icon-guanbi1" />
                  </svg>
                </div>
                <div class="addList">{{ addList.name }}</div>
              </div>
            </div>
          </div>
          <div slot="footer" class="footer">
            <el-button @click="cancelAdd">取消</el-button>
            <el-button type="primary" @click="addCourseTargets">确认</el-button>
          </div>
        </el-dialog>
        <ConfirmDialog ref="deleteDialog" :title="`删除目标`" @on-ok="clearSingleStarget">
          <div style="width: 238px; margin: 0 auto;">
            <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
            {{ delName }}
          </div>
        </ConfirmDialog>
        <ConfirmDialog ref="deleteStargetDialog" :title="`清空`" @on-ok="clearStarget">
          <div style="width: 238px; margin: 0 auto;">
            <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
            确认清空已绑定目标吗？
          </div>
        </ConfirmDialog>
      </div>
    </div>
  </div>
</template>
<script>
import courseApi from '@api/course'
import CourseCatalog from './catalog/CourseCatalog'
import courseNewExam from './member/courseNewExam'
import CoursePractice from './CoursePractice'
import courseEva from './evaluation'
import CourseQuestionnaire from '../detail/CourseQuestionnaire'
import functionApi from '@api/functionApi'
import ossApi from '@api/oss'
import UpdateInput from '@components/common/version2/UpdateInput'
import ConfirmDialog from '@components/common/dialog/ConfirmDialog'
import YTIcon from '@components/common/YTIcon'

export default {
  components: {
    UpdateInput,
    CourseQuestionnaire,
    CourseCatalog,
    CoursePractice,
    courseEva,
    courseNewExam,
    ConfirmDialog,
    YTIcon
  },
  data() {
    return {
      subGroup: [CourseCatalog, courseNewExam, CourseQuestionnaire, courseEva],
      delName: '',
      serveLoca: '',
      targetLists: [],
      targetVal: [],
      showAddTarget: false,
      uploadUrl: '',
      uploadParams: {
        key: '',
        policy: '',
        OSSAccessKeyId: '',
        success_action_status: '200',
        Signature: ''
      },
      hoverAvatar: false,
      showUpdateDes: false,
      showUpdateName: false,
      currentTab: '0',
      course: {},
      courseId: '',
      liveList: [],
      courseList: [],
      abilityList: [],
      selectAbilityId: [],
      allAbility: [],
      addModal: false,
      loading: false,
      targetList: [],
      targetsEnterId: 0,
      delTargetId: 0,
      targetListId: 0,
      knowledgeId: 0,
      showDel: false,
      fromUrl: '' //记录跳转来的路径
    }
  },
  created() {
    this.loading = true
    let params = this.$handleParams('courseDetailData')
    this.courseId = params.courseId
    this.getAddTarget()
    this.getCourseAbility()
    this.getInfos()
  },
  methods: {
    getInfos() {
      courseApi.getCourseDetail(this.courseId).then(res => {
        let data = res.res
        data.chapters = data.chapters
          .map(chapter => {
            chapter.sections.sort((a, b) => a.sequence - b.sequence)
            return chapter
          })
          .sort((a, b) => a.sequence - b.sequence)
        this.course = data
      })
      courseApi.getLatestLiveList(this.courseId).then(res => {
        this.liveList = res.res
      })
      functionApi
        .getAllAbilityData()
        .then(res => {
          this.allAbility = res.res
        })
        .finally(() => {
          this.loading = false
        })
    },
    updateClass() {
      let payload = {
        courseId: this.course.courseId,
        cover: this.course.cover,
        description: this.course.description,
        name: this.course.name
      }
      courseApi.editCourse(payload).then(res => {
        if (res.code === 0) {
          this.$message.success('编辑成功')
        }
      })
    },
    handleUploadCoverSuccess(response, file) {
      if (window.uploadUrl) {
        this.course.cover = this.serveLoca
      } else {
        this.course.cover = `${this.uploadUrl}/${this.uploadParams.key}`
      }
      this.updateClass()
    },
    beforeUpload(file) {
      if (window.uploadUrl) {
        // 局域网
        let forData = new FormData()
        forData.append('file', file)
        ossApi.upCover(forData).then(res => {
          this.serveLoca = res.res
          this.handleUploadCoverSuccess()
        })
      } else {
        ossApi.upType().then(() => {
          ossApi.getQuestionBankCoverToken(new Date().valueOf(), file.name).then(res => {
            const data = res.res
            this.uploadUrl = `https://${data.host}`
            this.uploadParams.key = data.dir
            this.uploadParams.OSSAccessKeyId = data.accessId
            this.uploadParams.policy = data.policy
            this.uploadParams.Signature = data.signature
            let formData = new FormData()
            formData.append('key', this.uploadParams.key)
            formData.append('OSSAccessKeyId', this.uploadParams.OSSAccessKeyId)
            formData.append('policy', this.uploadParams.policy)
            formData.append('Signature', this.uploadParams.Signature)
            formData.append('file', file)
            fetch(`https://${data.host}`, {
              method: 'POST',
              body: formData
            }).then(data => {
              this.handleUploadCoverSuccess()
            })
          })
        })
      }
      return false
    },
    nameUpdate(data) {
      if (data === 'name') {
        this.$refs.skillUpdateRateNameInput.setUpdate()
        this.$refs.skillUpdateDescriptionInput.closeUpdate()
      } else {
        this.$refs.skillUpdateRateNameInput.closeUpdate()
        this.$refs.skillUpdateDescriptionInput.setUpdate()
      }
    },
    showDele(data) {
      this.showDel = true
      this.knowledgeId = data.id
    },
    clearStarget() {
      courseApi.clearCourseTarget(this.courseId).then(res => {
        if (res.code === 0) {
          this.$message.success('目标已清空')
          this.getAddTarget()
          this.$refs.componentList.getCourseDetail()
          this.$refs.componentList.getTarget()
        }
      })
    },
    bindTarget() {
      this.showAddTarget = true
      this.getTarget()
      this.getAddTarget()
    },
    clearSingleStarget() {
      courseApi.delCourseTarget(this.courseId, this.delTargetId).then(res => {
        if (res.code === 0) {
          this.$message.success('目标已删除')
          this.getAddTarget()
          this.delName = ''
          this.$refs.componentList.getCourseDetail()
          this.$refs.componentList.getTarget()
        }
      })
    },
    deltarget(data) {
      this.delTargetId = data.id
      this.$refs.deleteDialog.visible = true
      this.delName = '确定删除' + data.name + '吗？'
    },
    targetsEnter(data) {
      this.targetsEnterId = data.id
    },
    getTarget() {
      courseApi.getTarget().then(res => {
        if (res.code === 0) {
          this.targetList = res.res
        }
      })
    },
    cancelAdd() {
      this.targetVal = []
      this.showAddTarget = false
    },
    getAddTarget() {
      courseApi.getCourseTarget(this.courseId).then(res => {
        if (res.code === 0) {
          this.targetLists = res.res
        }
      })
    },
    addCourseTargets() {
      if (this.targetVal.length === 0) {
        this.showAddTarget = false
      } else {
        let payload = {
          courseId: this.courseId,
          targetIds: this.targetVal
        }
        courseApi.addCourseTarget(payload).then(res => {
          if (res.code === 0) {
            this.$message.success('目标添加成功')
            this.getAddTarget()
            this.$refs.componentList.getCourseDetail()
            this.targetVal = []
          }
        })
      }
    },
    toCourse() {
      if (this.fromUrl === 'postCourseList') {
        this.$router.push({ path: '/manage/post/course' })
      } else {
        this.$router.push({ path: '/manage/study/course' })
      }
    },
    updateClassName(data, info) {
      if (info === 'name') {
        this.course.name = data
      } else {
        this.course.description = data
      }
      this.updateClass()
    },
    getCourseAbility() {
      courseApi.getCourseAbility(this.courseId).then(res => {
        if (res.code === 0) {
          this.abilityList = res.res
        }
      })
    },
    addCourseAbility() {
      this.loading = true
      let arr = this.abilityList.map(item => {
        return item.abilityId
      })
      let ids = this.$refs.cascader.getCheckedNodes().map(item => {
        return item.value
      })
      for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < ids.length; j++) {
          if (ids[j] === arr[i]) {
            ids.splice(j, 1)
            j = j - 1
          }
        }
      }
      if (ids.length === 0) {
        this.$message.warning('已有该能力, 请重新选择')
        this.loading = false
        return
      }
      courseApi
        .addCourseAbility(this.courseId, ids)
        .then(res => {
          if (res.res) {
            this.$message.success('添加成功')
            this.addModal = false
            this.selectAbilityId = []
            this.getCourseAbility()
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fromUrl = from.name
    })
  },
  computed: {
    hasLive() {
      return this.liveList && this.liveList.length
    }
  }
}
</script>
<style lang="less" scoped>
@import '~@/theme/variables';
.content {
  text-align: left;
  background-color: white;
  height: 100%;
  overflow-y: auto;
  box-shadow: 0 0 5px 0 rgba(7, 27, 41, 0.1);

  ::v-deep .ivu-tag {
    cursor: default;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #e4e9ef;
    font-size: 16px;
    color: @font-color-content;

    .course-operation {
      font-size: 12px;

      span {
        margin-left: 6px;
        cursor: pointer;
        color: @primary-color;
        transition: color @default-transition;

        &:hover {
          color: @primary-hover-color;
        }

        &:active,
        &:focus {
          color: @primary-active-color;
        }
      }
    }
  }
  .detailCover {
    background-color: rgba(0, 0, 0, 0.5);
    height: 200px;
    width: 314px;
    position: absolute;
    left: 0px;
    top: 0px;
  }
  .detail {
    display: flex;
    margin: 10px 20px 20px;
    min-width: 1140px;
    background: #ffffff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    border-radius: 2px;

    img {
      width: 314px;
      height: 200px;
    }

    .right-detail {
      width: calc(100% - 334px);
      flex: 13 1 auto;
      margin: 12px 0 0 20px;
      color: @font-color-content;
      font-size: 14px;
      padding-right: 40px;

      .detail-item {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 12px;
        color: #666666;
        white-space: nowrap;
        position: relative;
        p {
          font-size: 20px;
          font-weight: bold;
          line-height: 28px;
          color: #000000;
        }
        span {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: #000000;
          white-space: normal;
        }
        .icon {
          font-size: 19px;
          margin-left: 12px;
        }
        .info {
          font-size: 10px;
          font-weight: 400;
          line-height: 14px;
          color: #666666;
          .icon {
            font-size: 15px;
            margin-left: 0;
          }
        }
        .fun {
          display: flex;
          align-items: center;
          width: 56px;
          height: 24px;
          background: #ffffff;
          border: 1px solid #d9d9d9;
          opacity: 1;
          border-radius: 0 0 4px 4px;
          span {
            display: block;
            font-size: 20px;
            width: calc(100% / 2);
            height: 100%;
            text-align: center;
            align-items: center;
            cursor: pointer;
            &:nth-child(1):after {
              content: '';
              margin-left: 5px;
              border-left: 1px solid #c0c4cc;
              height: 100%;
              display: inline-block;
              vertical-align: middle;
            }
          }
        }
      }
    }

    .live-container {
      flex: 5 0 350px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-left: 2px solid #6a8abe;
      padding: 0 20px;
      margin: 40px 0;
      font-size: 14px;
      color: @font-color-content;

      .live-header {
        margin-bottom: 10px;
        font-weight: bold;
      }

      .live-btn {
        width: 120px;
        margin-top: 10px;
      }
    }
  }

  .opt-btn-container {
    padding: 0 24px;
    margin-bottom: 10px;

    .filter-btn {
      min-width: 100px;
      margin-right: 20px;
    }
  }

  ::v-deep .tabs {
    .el-tabs__header {
      margin: 0;
    }
    .el-tabs__active-bar {
      width: 60px !important;
      left: -15px !important;
      background-color: #438bff !important;
    }
    .el-tabs__nav-wrap {
      padding: 0 20px;
      font-size: 12px;
      &:after {
        height: 1px !important;
      }
    }
    .el-tabs__item {
      padding: 0 20px !important;
    }
    .is-active {
      color: #438bff !important;
    }
  }
}
.ability {
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(228, 233, 239, 1);
  opacity: 1;
  border-radius: 4px;
  overflow: auto;
  margin-top: 16px;
  padding: 0 8px;
  max-height: calc(100vh - 325px);
  .list {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border-radius: 4px;
    margin: 4px;
    &:hover {
      background: #ebf0f6 !important;
    }
  }
}
.delModal ::v-deep .ivu-modal-body {
  padding: 0 50px 20px 50px;
}
::v-deep .ivu-upload-drag {
  background: none;
  border: none;
  &:hover {
    border: none;
  }
}
.toCourse {
  cursor: pointer;
  &:hover {
    color: #2d8cf0;
  }
}
.addTarget {
  display: flex;
  justify-content: center;
  .left {
    width: 450px;
    height: 369px;
    background: #ffffff;
    border: 1px solid #e2e4e8;
    border-radius: 6px;
    margin-right: 40px;
    overflow-y: auto;
  }
  .right {
    width: 450px;
    height: 369px;
    background: #ffffff;
    border: 1px solid #e2e4e8;
    border-radius: 6px;
    overflow-y: auto;
    ::v-deep .el-button {
      width: 60px;
      height: 20px;
      background: #edf4ff !important;
      border-radius: 10px !important;
      color: #438bff !important;
      .flexStyle(flex, center, center);
    }
    span {
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      color: #000000;
    }
    .addList {
      font-size: 14px;
      line-height: 20px;
      color: #000000;
    }
  }
  ::v-deep .target-checkbox {
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    display: flex;
    align-items: center;
  }
  ::v-deep .ivu-checkbox-group {
    height: calc(100vh - 489px);
  }
}
.top {
  color: #747475;
  font-size: 14px;
  padding: 20px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}
.addList {
  height: 30px;
  color: #555555;
  font-size: 13px;
  padding: 20px;
  display: flex;
  align-items: center;
}
.addListName {
  cursor: pointer;
  padding: 12px;
  height: 20px;
  background: #edf4ff;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  margin-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: #438bff !important;
  }
  .del {
    width: 16px;
    height: 16px;
    background: #438bff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      color: #ffffff;
    }
  }
}
.target-checkbox {
  width: 100%;
  height: 44px;
  padding-left: 20px;
  line-height: 44px;
  margin-right: 0;
  text-align: left;
  &:hover {
    background-color: #ecf2fc;
  }
  ::v-deep .ivu-checkbox {
    margin-right: 8px;
  }
}
</style>
