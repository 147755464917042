<template>
  <el-dialog :visible="status" class="yt-dialog yt-dialog-default" :close-on-click-modal="false" width="1080px" @close="cancelAdd">
    <div class="dialog-title" slot="title">
      <p>新增随机练习</p>
      <p>- 系统会从相关联的阶段中随机出题组成练习试卷 -</p>
    </div>
    <div class="addTarget">
      <el-form class="yt-form" :model="dateForm" ref="validateForm">
        <div class="tag">
          <span>选择章节</span>
        </div>
        <el-form-item label-width="102px" label="新增练习:">
          <el-radio-group v-model="dateForm.type" :disabled="disabled" style="margin-left: 8px" @change="radioFun">
            <el-radio :label="0">本章练习</el-radio>
            <el-radio :label="1">小节练习</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="dateForm.type"
          label-width="100px"
          label="小节名称"
          prop="sectionId"
          :rules="{
            required: true,
            message: '请选择小节名称',
            trigger: 'change'
          }"
        >
          <el-select class="section-name" :disabled="disabled" v-model="dateForm.sectionId" value-key="sectionId" placeholder="请选择小节名称">
            <el-option v-for="item in options" :key="item.sectionId" :label="item.name" :value="item.sectionId"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label-width="123px"
          label="知识点选取："
          prop="knowledgeIds"
          :rules="{
            required: true,
            message: '请选择知识点',
            trigger: 'change'
          }"
        >
          <div class="Knowledge-points">
            <ul>
              <li v-for="(data, index) in dateForm.type ? knowledge : knowledgeData" :key="index">
                <span>{{ data.knowledgeName }}</span
                ><i
                  style="margin-left: 5px;cursor: pointer"
                  class="el-icon-circle-close"
                  v-show="dateForm.type"
                  @click="pointDelete(data.knowledgeId, index)"
                ></i>
              </li>
              <el-dropdown
                v-if="dateForm.type && knowledge.length < knowledgePointData.length"
                trigger="click"
                placement="top-start"
                :hide-on-click="false"
              >
                <YTIcon :href="'#icon-xinzeng'" />
                <el-dropdown-menu slot="dropdown">
                  <div style="height: 400px;overflow-y: auto">
                    <el-dropdown-item v-for="(knowledge, index) in knowledgePointData" :key="index">
                      <p>
                        <el-checkbox @change="handleClick(knowledge, index)" v-model="knowledge.checked">{{ knowledge.knowledgeName }}</el-checkbox>
                      </p>
                    </el-dropdown-item>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </ul>
          </div>
        </el-form-item>
        <hr />
        <div class="tag">
          <span>设置练习</span>
        </div>
        <div class="exercise-settings">
          <el-form-item
            label-width="93px"
            label="练习名称"
            prop="name"
            :rules="{
              required: true,
              message: '请输入练习名称',
              trigger: 'blur'
            }"
          >
            <el-input v-model="dateForm.name" maxlength="30" style="margin-right: 39px" placeholder="请输入练习名称"></el-input>
          </el-form-item>
          <el-form-item
            label="练习难度"
            prop="difficulty"
            :rules="{
              required: true,
              message: '请选择练习难度',
              trigger: 'change'
            }"
          >
            <el-select v-model="dateForm.difficulty" value-key="sectionId" placeholder="请选择练习难度" @change="getTypeCount">
              <el-option v-for="item in difficultyInfo" :key="item.label" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label-width="93px"
            label="练习时长"
            prop="limitTime"
            :rules="{
              required: true,
              message: '请输入练习时长',
              trigger: 'blur'
            }"
          >
            <el-input
              v-model="dateForm.limitTime"
              maxLength="3"
              oninput="value=Number(value.replace(/[^\d.]/g,''))"
              style="margin-right: 39px"
              placeholder="请输入练习时长"
            >
              <template slot="append">分钟</template>
            </el-input>
          </el-form-item>
          <el-form-item
            label-width="142px"
            label="是否允许多次练习："
            prop="isRepeat"
            :rules="{
              required: true,
              message: '请选择小节名称',
              trigger: 'change'
            }"
          >
            <el-radio-group v-model="dateForm.isRepeat" style="margin-left: 8px">
              <el-radio :label="'true'"
                >是
                <el-tooltip class="item" effect="dark" content="允许用户多次参加该练习，会以用户最好成绩为准。" placement="top-start">
                  <YTIcon style="color: #999999;margin-right: 10px;font-size: 14px" :href="'#icon-tishi3'" /> </el-tooltip
              ></el-radio>
              <el-radio :label="'false'">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <hr />
        <div class="tag">
          <div class="fun">
            <span>选择出题题库</span>
            <span @click="deleteAll">清空</span>
          </div>
        </div>
        <div class="question-bank">
          <el-form-item
            prop="questionBankIds"
            :rules="{
              required: true,
              message: '请选择题库',
              trigger: 'change'
            }"
          >
            <el-select
              class="section-name"
              multiple
              filterable
              default-first-option
              v-model="dateForm.questionBankIds"
              value-key="sectionId"
              placeholder="请选择题库"
              @change="getQuestionBankData"
            >
              <el-option v-for="item in questionBankList" :key="item.questionBankId" :label="item.name" :value="item.questionBankId"> </el-option>
            </el-select>
          </el-form-item>
        </div>
        <hr />
        <div class="tag">
          <span>设置题型数量</span>
          <span style="margin-left: 10px">总分{{ dateForm.score }}</span>
        </div>
        <div class="question-ratio">
          <el-form-item
            v-for="questionTypeCounts in dateForm.questionTypeCounts"
            :key="questionTypeCounts.type"
            label-width="93px"
            :label="questionTypeCounts.title"
          >
            <el-input-number
              v-model="questionTypeCounts.count"
              controls-position="right"
              :min="0"
              :max="questionTypeCounts.total"
              @change="setTotal"
            ></el-input-number>
            <span style="margin-left: 10px">{{ `数量${questionTypeCounts.total >= 999 ? '999+' : questionTypeCounts.total}` }}</span>
            <span style="margin-left: 10px">{{ `分值${getScore(questionTypeCounts)}` }}</span>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div slot="footer" class="footer">
      <el-button @click="cancelAdd">取消</el-button>
      <el-button type="primary" @click="addCourseTargets" :loading="createLoading">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import courseApi from '@api/course'
import YTIcon from '@components/common/YTIcon'
import questionBankApi from '@api/questionBank'
import questionApi from '@api/question'
import practiceApi from '@api/practice'
export default {
  name: 'AddPracticeDialog',
  components: { YTIcon },
  data() {
    return {
      createLoading: false,
      section: null,
      practiceId: '',
      disabled: false,
      dateForm: {
        name: '',
        type: 0,
        sectionId: null,
        knowledgeIds: [],
        limitTime: null,
        score: 0,
        isRepeat: 'true',
        questionBankIds: [],
        difficulty: null,
        questionTypeCounts: [
          { title: '判断题', questionTypeScore: 0, count: 0, type: 0, total: 0, totalScore: 0 },
          { title: '单选题', questionTypeScore: 0, count: 0, type: 1, total: 0, totalScore: 0 },
          { title: '多选题', questionTypeScore: 0, count: 0, type: 2, total: 0, totalScore: 0 },
          { title: '填空题', questionTypeScore: 0, count: 0, type: 3, total: 0, totalScore: 0 },
          { title: '代码题', questionTypeScore: 0, count: 0, type: 5, total: 0, totalScore: 0 }
        ]
      },
      chapter: null,
      status: false,
      options: [],
      knowledge: [],
      knowledgeData: [],
      questionBankId: [],
      questionBankList: [],
      knowledgePointData: [],
      difficultyInfo: [
        {
          label: '简单',
          value: 3
        },
        {
          label: '中等',
          value: 5
        },
        {
          label: '困难',
          value: 7
        }
      ]
    }
  },
  computed: {
    getScore() {
      return data => {
        return data.questionTypeScore * data.count
      }
    }
  },
  methods: {
    //清除所有
    deleteAll() {
      this.dateForm.questionBankIds = []
      this.getTypeCount()
    },
    //打开
    open(data, section) {
      this.status = true
      this.section = section
      if (section) {
        this.disabled = true
        this.practiceId = section.practiceId
        this.getRandomInfo(section)
      }
      this.getSections(data)
      this.getBank()
      this.getChapterKnowledge()
    },
    //删除知识点
    pointDelete(id, index) {
      if (this.dateForm.type) {
        this.knowledge.splice(index, 1)
        for (const idElement of this.knowledgePointData) {
          if (idElement.knowledgeId === id) {
            idElement.checked = false
          }
        }
      } else {
        this.knowledgeData.splice(index, 1)
      }
      this.dateForm.knowledgeIds.splice(index, 1)
      this.getTypeCount()
    },
    //计算总分
    setTotal() {
      this.dateForm.score = this.dateForm.questionTypeCounts.reduce((pre, cur) => {
        this.getScore(cur)
        return pre + cur.questionTypeScore * cur.count
      }, 0)
    },
    //全选
    getQuestionBankData(data) {
      if (data.includes('all')) {
        this.dateForm.questionBankIds = this.questionBankList.reduce((cur, next) => {
          this.dateForm.questionBankIds[next.questionBankId]
            ? ''
            : (this.dateForm.questionBankIds[next.questionBankId] = true && cur.push(next.questionBankId))
          return cur
        }, []) //默认cur是空的数组
        this.dateForm.questionBankIds.splice(0, 1)
      }
      this.getTypeCount()
    },
    //切换清除
    radioFun(data) {
      if (Number(data)) {
        this.knowledge = []
        this.dateForm.knowledgeIds = []
      }
    },
    //获取题型数量
    getTypeCount() {
      if (this.dateForm.knowledgeIds.length && this.dateForm.difficulty && this.dateForm.questionBankIds.length) {
        questionApi.getTypeCount(this.dateForm).then(res => {
          if (res.code === 0) {
            for (const reElement of res.res) {
              for (const reElementElement of this.dateForm.questionTypeCounts) {
                if (reElementElement.type === reElement.questionType) {
                  this.$set(reElementElement, 'total', reElement.questionCount)
                  this.$set(reElementElement, 'questionTypeScore', reElement.questionTypeScore)
                  if (reElementElement.count >= reElementElement.total) {
                    this.$set(reElementElement, 'count', reElementElement.total)
                  }
                }
              }
            }
          }
        })
      } else {
        for (const reElementElement of this.dateForm.questionTypeCounts) {
          this.$set(reElementElement, 'total', 0)
          this.$set(reElementElement, 'questionTypeScore', 0)
          if (reElementElement.count >= reElementElement.total) {
            this.$set(reElementElement, 'count', reElementElement.total)
          }
        }
      }
    },
    //知识点添加方法
    handleClick(data, index) {
      if (data.checked) {
        const isExistence = this.knowledge.some(res => res.knowledgeId === data.knowledgeId)
        if (!isExistence) {
          this.knowledge.push(data)
          this.dateForm.knowledgeIds.push(data.knowledgeId)
        }
      } else {
        this.pointDelete(data.knowledgeId, index)
      }

      this.getTypeCount()
    },
    //获取章节绑定的知识
    getChapterKnowledge() {
      courseApi.getChapterKnowledge(this.chapter.chapterId).then(res => {
        if (res.code === 0) {
          if (!this.section) {
            this.knowledgeData = res.res
            this.dateForm.knowledgeIds = res.res.map(data => data.knowledgeId)
          }
          this.knowledgePointData = res.res.map(data => {
            this.$set(data, 'checked', false)
            return data
          })
        }
      })
    },
    //获取随机练习信息
    getRandomInfo(section) {
      practiceApi.getRandomInfo(section.practiceId).then(res => {
        if (res.code === 0) {
          for (let key of Object.keys(this.dateForm)) {
            switch (key) {
              case 'type':
                this.dateForm[key] = res.res[key]
                break
              case 'isRepeat':
                this.dateForm[key] = String(res.res[key])
                break
              case 'knowledgeIds':
                if (res.res.type) {
                  this.matchKnowledge(res.res.knowledgeList)
                } else {
                  this.knowledgeData = res.res.knowledgeList
                  this.dateForm[key] = res.res.knowledgeList.map(data => data.knowledgeId)
                }
                break
              case 'questionTypeCounts':
                this.matchTypeCount(res.res[key], this.dateForm[key])
                break
              default:
                this.dateForm[key] = res.res[key]
            }
          }
        }
      })
    },
    matchKnowledge(data) {
      this.knowledge = data
      this.dateForm.knowledgeIds = data.map(data => {
        for (const idElement of this.knowledgePointData) {
          if (idElement.knowledgeId === data.knowledgeId) {
            this.$set(idElement, 'checked', true)
          }
        }
        return data.knowledgeId
      })
    },
    //匹配题目数量
    matchTypeCount(param, data) {
      for (const reElement of param) {
        for (const reElementElement of data) {
          if (reElementElement.type === reElement.type) {
            this.$set(reElementElement, 'count', reElement.count)
            this.$set(reElementElement, 'total', reElement.maxCount)
            this.$set(reElementElement, 'questionTypeScore', reElement.questionTypeScore)
          }
        }
      }
    },
    //获取题库
    getBank() {
      questionBankApi.searchSimpleInfoByPermission().then(res => {
        if (res.code === 0) {
          this.questionBankList = res.res
          this.questionBankList.unshift({ questionBankId: 'all', name: '全选' })
        }
      })
    },
    getSections(data) {
      this.chapter = data
      courseApi.getSections(data.chapterId).then(res => {
        if (res.code === 0) {
          this.options = res.res
        }
      })
    },
    cancelAdd() {
      this.status = false
      Object.assign(this.$data, this.$options.data())
      //关闭时清除表单验证
      this.$refs['validateForm'].clearValidate()
    },
    //创建和修改
    addCourseTargets() {
      let num = this.dateForm.questionTypeCounts.reduce((cur, pre) => {
        return cur + pre.count
      }, 0)
      this.$refs['validateForm'].validate(valid => {
        if (valid) {
          if (num >= 1) {
            this.createLoading = true
            Object.assign(this.dateForm, { chapterId: this.chapter.chapterId, courseId: this.chapter.courseId, practiceId: this.practiceId })
            if (this.disabled) {
              practiceApi
                .modifyPracticeRandom(this.dateForm)
                .then(res => {
                  if (res.code === 0) {
                    this.$message.success('随机练习修改成功')
                    this.status = false
                    this.createLoading = false
                    this.$parent.getData()
                  }
                })
                .finally(() => {
                  this.createLoading = false
                })
            } else {
              practiceApi
                .getPracticeRandom(this.dateForm)
                .then(res => {
                  if (res.code === 0) {
                    this.$message.success('随机练习创建成功')
                    this.status = false
                    this.createLoading = false
                    this.$parent.getData()
                  }
                })
                .finally(() => {
                  this.createLoading = false
                })
            }
          } else {
            this.$message.warning('总题型数量不能为0')
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/variables';
.yt-dialog {
  ::v-deep .el-dialog__header {
    line-height: 11px;
    height: 90px;
  }
  ::v-deep .el-dialog__body {
    padding: 20px 40px;
    overflow-y: auto;
  }
  .dialog-title {
    padding: 20px;
    p:nth-child(1) {
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      color: #282c3d;
      margin-bottom: 10px;
    }
    p:nth-child(2) {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #999999;
      padding-bottom: 20px;
    }
  }
}
.addTarget {
  .tag {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    &:before {
      content: '*';
      color: #f56c6c;
      margin-right: 4px;
    }
    .fun {
      display: flex;
      width: 100%;
      .flexStyle(flex, space-between, center);
      span {
        &:last-child {
          height: 20px;
          background: #f0f0f0;
          border-radius: 10px;
          font-size: 12px;
          line-height: 5px;
          color: #666666;
          padding: 7px 12px;
          cursor: pointer;
          user-select: none;
        }
      }
    }
    p {
      width: 4px;
      height: 20px;
      background: #438bff;
      border-radius: 1px;
      margin-right: 16px;
    }
    span {
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      color: #000000;
    }
  }
  .Knowledge-points {
    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      line-height: 45px;
      li {
        height: 22px;
        line-height: 19px;
        background: #edf4ff;
        list-style-type: none;
        margin-right: 12px;
        padding: 2px 12px;
        color: #448bff;
        margin-bottom: 5px;
      }
    }
    svg {
      display: inline-block;
      height: 20px;
    }
  }
  hr {
    height: 1px;
    border: none;
    background: #eeeeee;
    margin: 20px 0;
    box-sizing: border-box;
  }
  .section-name {
    width: 100%;
    ::v-deep .el-input {
      width: 100%;
    }
  }
  .exercise-settings {
    display: flex;
    flex-wrap: wrap;
    ::v-deep .el-input {
      width: 350px;
    }
    ::v-deep .el-form-item {
      display: flex;
      width: 50%;
    }
    ::v-deep .el-form-item__content {
      margin-left: 0 !important;
    }
  }
  .question-ratio {
    display: flex;
    flex-wrap: wrap;
    ::v-deep .el-form-item {
      width: calc(100% / 3);
    }
    ::v-deep .el-input-number {
      width: 120px;
      .el-input {
        width: 120px;
      }
      .el-input-number__decrease,
      .el-input-number__increase {
        background: #ffffff;
      }
    }
  }
  ::v-deep .el-radio {
    margin-right: 80px;
  }
  ::v-deep .el-form-item {
    margin-bottom: 20px;
    .el-form-item__label:before {
      content: '';
    }
  }
}
::v-deep .el-dialog__body {
  max-height: calc(100vh - 221px) !important;
}
::v-deep .el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.el-dialog .el-dialog__body {
  flex: 1;
  overflow: auto;
}
</style>
