<template>
  <!--编辑框组件-->
  <div class="update-wrapper">
    <template v-if="isUpdate">
      <div class="update-wrapper">
        <template v-if="type === 'textarea'">
          <el-input v-model="value" type="textarea" autosize resize="none" :maxLength="maxLength" placeholder="请输入内容"> </el-input>
        </template>
        <template v-else-if="type === 'number'">
          <el-input-number v-model="value" :min="0" :max="999" :controls="false" />
        </template>
        <template v-else-if="type === 'autocomplete'">
          <el-autocomplete
            v-model="value"
            :fetch-suggestions="querySearch"
            suffix-icon="el-icon-arrow-down"
            placeholder="请输入"
            :maxlength="maxLength"
            @input="oninputEvent"
            @change="handleAutocompleteChange"
            @select="handleAutocompleteSelect"
          ></el-autocomplete>
        </template>
        <template v-else>
          <el-input v-model="value" :maxLength="maxLength" @change="handleInputChange" placeholder="请输入内容" />
        </template>
        <div class="update-tools" :class="{ 'no-submit': !submit }">
          <i v-if="submit" class="el-icon-check" style="color: #FF5050" @click="setValue"></i>
          <i class="el-icon-close" style="color: #4CE47A" @click="closeUpdate"></i>
        </div>
      </div>
    </template>
    <template v-else>
      <div @click="toUpdate">
        <slot />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'UpdateInput',
  props: {
    updateData: {
      type: String | Number,
      default: ''
    },
    type: {
      type: String,
      default: 'default'
    },
    maxLength: {
      type: Number,
      default: 0
    },
    postType: {
      type: Number,
      default: 0
    },
    submit: {
      type: Boolean,
      default: true
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isUpdate: false,
      value: '',
      updateLoading: false,
      hasCloseMethod: false
    }
  },
  mounted() {
    this.hasCloseMethod = this.$listeners['closeUpdate'] !== undefined
  },
  methods: {
    toUpdate() {
      //进入编辑模式, 平台岗位模型不可编辑
      if (this.postType === 2 || this.hasCloseMethod) {
        return
      }
      this.isUpdate = true
      this.value = this.updateData
    },
    setValue() {
      //提交
      this.$emit('setValue', this.value)
      this.closeUpdate()
    },
    setUpdate() {
      this.isUpdate = true
      this.value = this.updateData
    },
    setError(msg) {
      this.$message.error(msg)
    },
    setClose() {
      this.isUpdate = false
      this.value = ''
      this.updateLoading = false
    },
    closeUpdate() {
      //关闭
      if (this.hasCloseMethod) {
        this.$emit('closeUpdate')
      } else {
        this.isUpdate = false
        this.value = ''
        this.updateLoading = false
      }
    },
    querySearch(queryString, cb) {
      this.$emit('querySearch', queryString, res => {
        // 调用 callback 返回建议列表的数据
        cb(res)
      })
    },
    oninputEvent(value) {
      this.$emit('oninput', value, res => {
        this.value = res
      })
    },
    handleAutocompleteChange(value) {
      this.$emit('change', value)
    },
    handleAutocompleteSelect(value) {
      this.$emit('select', value)
    },
    handleInputChange(value) {
      this.$emit('change', value)
    }
  }
}
</script>

<style lang="less" scoped>
.update-wrapper {
  width: 100%;
  position: relative;
  ::v-deep .el-textarea__inner {
    padding: 10px 12px;
    line-height: 17px;
    font-size: @small;
    border-bottom-right-radius: 0;
  }
  ::v-deep .el-input-number {
    .el-input__inner {
      height: 38px;
      line-height: 38px;
      font-size: @small;
      text-align: left;
      border-bottom-right-radius: 0;
    }
  }
  ::v-deep .el-autocomplete {
    .el-input__inner {
      padding-left: 12px;
    }
  }
  .update-tools {
    height: 20px;
    line-height: 19px;
    border: 1px solid #d9d9d9;
    border-top: none;
    border-radius: 0 0 4px 4px;
    background-color: #fff;
    position: absolute;
    right: 0;
    &:after {
      content: '';
      width: 1px;
      height: 100%;
      background-color: #d9d9d9;
      position: absolute;
      left: 50%;
    }
    &.no-submit {
      &:after {
        display: none;
      }
    }
    i {
      width: 28px;
      height: 100%;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>
