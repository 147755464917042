var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container"},[_c('Table',{staticClass:"flex-table",attrs:{"columns":_vm.columns,"data":_vm.tableData,"loading":_vm.loading},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"nowrap",attrs:{"title":row.name}},[_vm._v(_vm._s(row.name))])]}},{key:"section",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"nowrap",attrs:{"title":((row.chapter) + " / " + (row.section))}},[_vm._v(" "+_vm._s(((row.chapter) + " / " + (row.section)))+" ")])]}},{key:"count",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(row.times))])]}},{key:"status",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"status-container"},[_c('div',{staticClass:"status",style:({ backgroundColor: _vm.getStatus(row.status).color })}),_vm._v(" "+_vm._s(_vm.getStatus(row.status).label)+" ")])]}},{key:"opt",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"operation"},[_c('Tooltip',{attrs:{"content":"详情","placement":"top"}},[_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.handleViewPractice(row)}}},[_c('use',{attrs:{"xlink:href":"#icon-xiangqing"}})])]),_c('Dropdown',{staticStyle:{"margin-left":"20px"},on:{"on-click":function($event){return _vm.handleClickMenu($event, row)}}},[_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#icon-gengduo-2"}})]),_c('DropdownMenu',{attrs:{"slot":"list"},slot:"list"},[(row.status !== _vm.PRACTICE_STATUS.PUBLISH.value)?_c('DropdownItem',{attrs:{"name":"edit"}},[_vm._v("编辑")]):_vm._e(),(row.status !== _vm.PRACTICE_STATUS.PUBLISH.value)?_c('DropdownItem',{attrs:{"name":"release"}},[_vm._v("发布")]):_c('DropdownItem',{attrs:{"name":"close"}},[_vm._v("关闭")]),_c('DropdownItem',{attrs:{"name":"delete"}},[_vm._v("删除")])],1)],1)],1)]}}])}),_c('Page',{staticStyle:{"margin-top":"10px"},attrs:{"size":"small","current":_vm.currentPage,"total":_vm.total},on:{"update:current":function($event){_vm.currentPage=$event}}}),_c('DeleteModal',{attrs:{"title":("确认删除练习 '" + (_vm.selectedPractice.name) + "'"),"loading":_vm.deleteLoading},on:{"on-ok":_vm.deletePractice},model:{value:(_vm.deleteVisible),callback:function ($$v) {_vm.deleteVisible=$$v},expression:"deleteVisible"}},[_c('span',[_vm._v("删除练习后不可恢复，你还要继续吗？")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }