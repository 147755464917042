var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"yt-dialog yt-dialog-default",attrs:{"visible":_vm.status,"close-on-click-modal":false,"width":"1080px"},on:{"close":_vm.cancelAdd}},[_c('div',{staticClass:"dialog-title",attrs:{"slot":"title"},slot:"title"},[_c('p',[_vm._v("新增随机练习")]),_c('p',[_vm._v("- 系统会从相关联的阶段中随机出题组成练习试卷 -")])]),_c('div',{staticClass:"addTarget"},[_c('el-form',{ref:"validateForm",staticClass:"yt-form",attrs:{"model":_vm.dateForm}},[_c('div',{staticClass:"tag"},[_c('span',[_vm._v("选择章节")])]),_c('el-form-item',{attrs:{"label-width":"102px","label":"新增练习:"}},[_c('el-radio-group',{staticStyle:{"margin-left":"8px"},attrs:{"disabled":_vm.disabled},on:{"change":_vm.radioFun},model:{value:(_vm.dateForm.type),callback:function ($$v) {_vm.$set(_vm.dateForm, "type", $$v)},expression:"dateForm.type"}},[_c('el-radio',{attrs:{"label":0}},[_vm._v("本章练习")]),_c('el-radio',{attrs:{"label":1}},[_vm._v("小节练习")])],1)],1),(_vm.dateForm.type)?_c('el-form-item',{attrs:{"label-width":"100px","label":"小节名称","prop":"sectionId","rules":{
          required: true,
          message: '请选择小节名称',
          trigger: 'change'
        }}},[_c('el-select',{staticClass:"section-name",attrs:{"disabled":_vm.disabled,"value-key":"sectionId","placeholder":"请选择小节名称"},model:{value:(_vm.dateForm.sectionId),callback:function ($$v) {_vm.$set(_vm.dateForm, "sectionId", $$v)},expression:"dateForm.sectionId"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.sectionId,attrs:{"label":item.name,"value":item.sectionId}})}),1)],1):_vm._e(),_c('el-form-item',{attrs:{"label-width":"123px","label":"知识点选取：","prop":"knowledgeIds","rules":{
          required: true,
          message: '请选择知识点',
          trigger: 'change'
        }}},[_c('div',{staticClass:"Knowledge-points"},[_c('ul',[_vm._l((_vm.dateForm.type ? _vm.knowledge : _vm.knowledgeData),function(data,index){return _c('li',{key:index},[_c('span',[_vm._v(_vm._s(data.knowledgeName))]),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.dateForm.type),expression:"dateForm.type"}],staticClass:"el-icon-circle-close",staticStyle:{"margin-left":"5px","cursor":"pointer"},on:{"click":function($event){return _vm.pointDelete(data.knowledgeId, index)}}})])}),(_vm.dateForm.type && _vm.knowledge.length < _vm.knowledgePointData.length)?_c('el-dropdown',{attrs:{"trigger":"click","placement":"top-start","hide-on-click":false}},[_c('YTIcon',{attrs:{"href":'#icon-xinzeng'}}),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('div',{staticStyle:{"height":"400px","overflow-y":"auto"}},_vm._l((_vm.knowledgePointData),function(knowledge,index){return _c('el-dropdown-item',{key:index},[_c('p',[_c('el-checkbox',{on:{"change":function($event){return _vm.handleClick(knowledge, index)}},model:{value:(knowledge.checked),callback:function ($$v) {_vm.$set(knowledge, "checked", $$v)},expression:"knowledge.checked"}},[_vm._v(_vm._s(knowledge.knowledgeName))])],1)])}),1)])],1):_vm._e()],2)])]),_c('hr'),_c('div',{staticClass:"tag"},[_c('span',[_vm._v("设置练习")])]),_c('div',{staticClass:"exercise-settings"},[_c('el-form-item',{attrs:{"label-width":"93px","label":"练习名称","prop":"name","rules":{
            required: true,
            message: '请输入练习名称',
            trigger: 'blur'
          }}},[_c('el-input',{staticStyle:{"margin-right":"39px"},attrs:{"maxlength":"30","placeholder":"请输入练习名称"},model:{value:(_vm.dateForm.name),callback:function ($$v) {_vm.$set(_vm.dateForm, "name", $$v)},expression:"dateForm.name"}})],1),_c('el-form-item',{attrs:{"label":"练习难度","prop":"difficulty","rules":{
            required: true,
            message: '请选择练习难度',
            trigger: 'change'
          }}},[_c('el-select',{attrs:{"value-key":"sectionId","placeholder":"请选择练习难度"},on:{"change":_vm.getTypeCount},model:{value:(_vm.dateForm.difficulty),callback:function ($$v) {_vm.$set(_vm.dateForm, "difficulty", $$v)},expression:"dateForm.difficulty"}},_vm._l((_vm.difficultyInfo),function(item){return _c('el-option',{key:item.label,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"label-width":"93px","label":"练习时长","prop":"limitTime","rules":{
            required: true,
            message: '请输入练习时长',
            trigger: 'blur'
          }}},[_c('el-input',{staticStyle:{"margin-right":"39px"},attrs:{"maxLength":"3","oninput":"value=Number(value.replace(/[^\\d.]/g,''))","placeholder":"请输入练习时长"},model:{value:(_vm.dateForm.limitTime),callback:function ($$v) {_vm.$set(_vm.dateForm, "limitTime", $$v)},expression:"dateForm.limitTime"}},[_c('template',{slot:"append"},[_vm._v("分钟")])],2)],1),_c('el-form-item',{attrs:{"label-width":"142px","label":"是否允许多次练习：","prop":"isRepeat","rules":{
            required: true,
            message: '请选择小节名称',
            trigger: 'change'
          }}},[_c('el-radio-group',{staticStyle:{"margin-left":"8px"},model:{value:(_vm.dateForm.isRepeat),callback:function ($$v) {_vm.$set(_vm.dateForm, "isRepeat", $$v)},expression:"dateForm.isRepeat"}},[_c('el-radio',{attrs:{"label":'true'}},[_vm._v("是 "),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"允许用户多次参加该练习，会以用户最好成绩为准。","placement":"top-start"}},[_c('YTIcon',{staticStyle:{"color":"#999999","margin-right":"10px","font-size":"14px"},attrs:{"href":'#icon-tishi3'}})],1)],1),_c('el-radio',{attrs:{"label":'false'}},[_vm._v("否")])],1)],1)],1),_c('hr'),_c('div',{staticClass:"tag"},[_c('div',{staticClass:"fun"},[_c('span',[_vm._v("选择出题题库")]),_c('span',{on:{"click":_vm.deleteAll}},[_vm._v("清空")])])]),_c('div',{staticClass:"question-bank"},[_c('el-form-item',{attrs:{"prop":"questionBankIds","rules":{
            required: true,
            message: '请选择题库',
            trigger: 'change'
          }}},[_c('el-select',{staticClass:"section-name",attrs:{"multiple":"","filterable":"","default-first-option":"","value-key":"sectionId","placeholder":"请选择题库"},on:{"change":_vm.getQuestionBankData},model:{value:(_vm.dateForm.questionBankIds),callback:function ($$v) {_vm.$set(_vm.dateForm, "questionBankIds", $$v)},expression:"dateForm.questionBankIds"}},_vm._l((_vm.questionBankList),function(item){return _c('el-option',{key:item.questionBankId,attrs:{"label":item.name,"value":item.questionBankId}})}),1)],1)],1),_c('hr'),_c('div',{staticClass:"tag"},[_c('span',[_vm._v("设置题型数量")]),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v("总分"+_vm._s(_vm.dateForm.score))])]),_c('div',{staticClass:"question-ratio"},_vm._l((_vm.dateForm.questionTypeCounts),function(questionTypeCounts){return _c('el-form-item',{key:questionTypeCounts.type,attrs:{"label-width":"93px","label":questionTypeCounts.title}},[_c('el-input-number',{attrs:{"controls-position":"right","min":0,"max":questionTypeCounts.total},on:{"change":_vm.setTotal},model:{value:(questionTypeCounts.count),callback:function ($$v) {_vm.$set(questionTypeCounts, "count", $$v)},expression:"questionTypeCounts.count"}}),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(("数量" + (questionTypeCounts.total >= 999 ? '999+' : questionTypeCounts.total))))]),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(("分值" + (_vm.getScore(questionTypeCounts)))))])],1)}),1)],1)],1),_c('div',{staticClass:"footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.cancelAdd}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.createLoading},on:{"click":_vm.addCourseTargets}},[_vm._v("确认")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }