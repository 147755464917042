<template>
  <div class="section-container" v-loading="loading" element-loading-text="加载中...">
    <div class="head">
      <div style="flex: auto">视频名称</div>
      <div style="width: 10%"></div>
      <div style="width: 10%"></div>
      <div style="width: 15%">观看/练习次数</div>
      <div style="width: 15%">上传时间</div>
      <div style="width: 10%">上传者</div>
      <div style="width: 10%">操作</div>
    </div>
    <div v-if="isOwner" class="chapter-add">
      <div v-if="!isAddingChapter" @click="isAddingChapter = true">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-xinzeng" />
        </svg>
        新增章节
      </div>
      <el-input
        v-else
        type="text"
        :class="{ error: !addNameValid }"
        @keyup.native.enter="addChapter"
        @input="handleCheckAddChapterName"
        @blur="isAddingChapter = false"
        placeholder="请输入章节名称(回车确认)"
        v-model="newChapterName"
        style="width: 40%"
        :maxlength="20"
        show-word-limit
      />
    </div>
    <div class="chapter" v-for="(chapter, chapterIndex) in chapters" :key="chapter.chapterId">
      <!--章节名称-->
      <div class="title">
        <div class="name" style="width: 85%">
          <div v-if="!chapter.isEdit" @click="getdown(chapter, chapterIndex)" style="display: flex;align-items: center">
            <YTIcon :href="'#icon-open'" @click="getdown(chapter, chapterIndex)" :class="{ spread: chapter.expand }" />
            <span style="white-space: nowrap;margin-left: 5px">{{ chapter.name }}</span>
            <div
              style="display: flex;overflow: hidden;color: #8DB0FFFF;margin-left: 12px;font-size: 14px"
              @click="addStage(chapter)"
              v-if="chapter.gradations !== undefined"
            >
              「
              <div v-for="stages in targetLists" :key="stages.id" style="display: flex;overflow: hidden;text-overflow: ellipsis;">
                <div v-for="(stage, index) in chapter.gradations" :key="index">
                  <div v-if="stages.id === stage.targetId">
                    <div v-if="index === 0" style="white-space: nowrap;">
                      {{ stages.name + '---' + stage.name }}
                    </div>
                    <div v-if="index === 1" style="white-space: nowrap;margin: 0 12px">
                      {{ stages.name + '---' + stage.name }}
                    </div>
                    <div v-if="index === 2" style="white-space: nowrap;">
                      {{ stages.name + '---' + stage.name }}
                    </div>
                    <div v-if="index === 3" style="white-space: nowrap;margin: 0 12px">
                      {{ stages.name + '---' + stage.name }}
                    </div>
                    <div v-if="index === 4" style="white-space: nowrap;">
                      {{ stages.name + '---' + stage.name }}
                    </div>
                  </div>
                </div>
              </div>
              <span v-if="chapter.gradations">{{ chapter.gradations.length > 5 ? '...' : '' }}</span>
              」
            </div>
          </div>
          <!--          <Input v-if="chapter.isEdit" placeholder="请输入章节名称" v-model="editChapterName" @on-enter="updateChapter"-->
          <!--                 @on-change="handleCheckEditChapterName" :class="{error: !editNameValid}">-->
          <!--            <span slot="suffix">-->
          <!--              <Icon type="md-checkmark" size="24" color="#78cf7d" v-show="editChapterName" @click="updateChapter"-->
          <!--                    style="padding: 0 4px"/>-->
          <!--              <Icon type="md-close" size="24" color="#afbacb" @click="$set(chapter, 'isEdit', false)"-->
          <!--                    style="padding-right: 4px"/>-->
          <!--            </span>-->
          <!--          </Input>-->
          <el-input
            v-if="chapter.isEdit"
            placeholder="请输入章节名称(回车确认)"
            maxlength="20"
            show-word-limit
            @blur="$set(chapter, 'isEdit', false)"
            v-model="editChapterName"
            :class="{ error: !editNameValid }"
            @keyup.native.enter="updateChapter"
            @input="handleCheckEditChapterName"
          />
        </div>

        <div v-if="isOwner" class="chapter-opt" style="display: flex;align-items: center">
          <el-dropdown placement="bottom-start">
            <p>练习</p>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item><p @click="addPractice(chapter, true)">手动练习</p></el-dropdown-item>
              <el-dropdown-item><p @click="addPractice(chapter, false)">随机练习</p></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown placement="bottom-start">
            <div @click="deleteProtectModalVisible = true">
              <p>小节</p>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-if="sectionType.show" v-for="sectionType in sectionTypes" :name="sectionType.value" :key="sectionType.value">
                <p @click="handleClickAddSection(sectionType.value, chapter)">{{ sectionType.label }}</p>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown placement="bottom-start">
            <p class="el-dropdown-link">更多</p>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item><p @click="handleEditChapter(chapter)">编辑</p></el-dropdown-item>
              <el-dropdown-item><p @click="handleDeleteChapter(chapter)">删除</p></el-dropdown-item>
              <el-dropdown-item><p @click="addStage(chapter)">绑定阶段</p></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <!--          <Dropdown class="practisesInfo" onClick="event.cancelBubble = true">-->
          <!--            <a @mouseover="getChapterPractices(chapter.chapterId)">-->
          <!--              <YTIcon :href="'#icon-shijuan2'" @click="getdown(chapter)" :class="{ spread: chapter.expand }" />-->
          <!--            </a>-->
          <!--            <Dropdown-menu slot="list">-->
          <!--              <div v-if="isOwner" style="color: #2d8cf0; padding: 5px" @click.stop="topot('chapter', null, chapter.name, chapter)">-->
          <!--                <svg-->
          <!--                  t="1590547128100"-->
          <!--                  class="icon"-->
          <!--                  viewBox="0 0 1024 1024"-->
          <!--                  version="1.1"-->
          <!--                  xmlns="http://www.w3.org/2000/svg"-->
          <!--                  p-id="2832"-->
          <!--                  width="16"-->
          <!--                  height="16"-->
          <!--                >-->
          <!--                  <path-->
          <!--                    d="M512 972.8a460.8 460.8 0 1 0 0-921.6 460.8 460.8 0 0 0 0 921.6z m0 51.2C229.2224 1024 0 794.7776 0 512S229.2224 0 512 0s512 229.2224 512 512-229.2224 512-512 512z m-232.704-486.4h465.408a25.6 25.6 0 1 0 0-51.2h-465.408a25.6 25.6 0 0 0 0 51.2zM486.4 279.296v465.408a25.6 25.6 0 1 0 51.2 0v-465.408a25.6 25.6 0 1 0-51.2 0z"-->
          <!--                    p-id="2833"-->
          <!--                    fill="#1296db"-->
          <!--                  ></path>-->
          <!--                </svg>-->
          <!--                新增练习-->
          <!--              </div>-->
          <!--              <Dropdown-item v-for="(item, index) in chapterPracticesList" :key="index">-->
          <!--                <div style="display: flex; justify-content: space-between">-->
          <!--                  <span @click="editorP(chapter, item.practiceId)">{{ item.name }}</span>-->
          <!--                  <span style="color: black" @click="deleteChapterPractice(chapter.chapterId, item.practiceId)">-->
          <!--                    <svg-->
          <!--                      t="1590547406955"-->
          <!--                      class="icon"-->
          <!--                      viewBox="0 0 1024 1024"-->
          <!--                      version="1.1"-->
          <!--                      xmlns="http://www.w3.org/2000/svg"-->
          <!--                      p-id="4038"-->
          <!--                      width="16"-->
          <!--                      height="16"-->
          <!--                    >-->
          <!--                      <path-->
          <!--                        d="M897.969231 842.830769L567.138462 512l330.830769-330.830769-55.138462-55.138462-330.830769 330.830769-330.830769-330.830769-55.138462 55.138462 330.830769 330.830769-330.830769 330.830769 55.138462 55.138462 330.830769-330.830769 330.830769 330.830769z"-->
          <!--                        fill="#1296db"-->
          <!--                        p-id="4039"-->
          <!--                      ></path>-->
          <!--                    </svg>-->
          <!--                  </span>-->
          <!--                </div>-->
          <!--              </Dropdown-item>-->
          <!--            </Dropdown-menu>-->
          <!--          </Dropdown>-->
          <!--          <Tooltip placement="top" content="新增小节">-->
          <!--            <transition name="fade" mode="out-in">-->
          <!--              <Dropdown trigger="click" @on-click="name => handleClickAddSection(name, chapter)">-->
          <!--                <div @click="deleteProtectModalVisible = true">-->
          <!--                  <svg class="icon" aria-hidden="true">-->
          <!--                    <use xlink:href="#icon-xinzeng" />-->
          <!--                  </svg>-->
          <!--                </div>-->
          <!--                <DropdownMenu slot="list">-->
          <!--                  <DropdownItem v-if="sectionType.show" v-for="sectionType in sectionTypes" :name="sectionType.value" :key="sectionType.value">-->
          <!--                    <span>{{ sectionType.label }}</span>-->
          <!--                  </DropdownItem>-->
          <!--                </DropdownMenu>-->
          <!--              </Dropdown>-->
          <!--            </transition>-->
          <!--          </Tooltip>-->
        </div>
      </div>
      <!--小节列表-->
      <!--      <transition-group name="fade" mode="out-in">-->
      <div class="sections" v-for="(section, sectionIndex) in chapter.sections" :key="section.sectionId" v-if="chapter.expand">
        <div class="title">
          <div class="name" v-if="!section.isEdit" style="flex: auto;padding-left: 8px;display: flex;align-items: center;">
            <!--            <svg class="icon arrow" :class="{ spread: expand1 === section.sectionId }" @click="getdown1(section)" aria-hidden="true">-->
            <!--              <use xlink:href="#icon-webicon215" />-->
            <!--            </svg>-->
            <!--            <svg class="icon" aria-hidden="true">-->
            <!--              <use :xlink:href="getIconBySectionType(section.type)" />-->
            <!--            </svg>-->
            <YTIcon
              :href="section.targetId ? typeIcon[section.type] : typeIcon[5]"
              slot="reference"
              @click="handleClickIcon(section)"
              style="width: 17px;height: 17px;margin-right: 7px;fill: #999999"
            ></YTIcon>
            <span v-if="section.sectionNum" class="section section-name" :title="section.name"
              >{{ `第${section.sectionNum > 10 ? section.sectionNum : `0${section.sectionNum}`}小节练习：` }}{{ section.name }}</span
            >
            <span v-else-if="section.practiceName" class="section section-name" :title="section.practiceName">{{
              `本章练习：${section.practiceName}`
            }}</span>
            <span v-else class="section section-name" @click=";(porVideo = true), getPorVideo(section)" :title="section.name"
              >{{ `第${String(sectionIndex + 1).length > 1 ? sectionIndex + 1 : `0${sectionIndex + 1}`}节：` }}{{ section.name }}</span
            >
          </div>
          <div style="width: 10%"></div>
          <div style="width: 10%"></div>
          <div style="width: 15%">{{ section.times }}</div>
          <!--小节特有的时间，只有视频和直播有，资料没有-->
          <div class="time" style="width: 15%">
            {{ $formatTime(section.createdTime, 'yyyy-MM-dd hh:mm') }}
          </div>
          <div style="width: 10%">{{ section.creatorName }}</div>

          <div style="width: 10%;">
            <div class="chapter-opt">
              <!--              <Tooltip placement="top" content="移动暂不支持">-->
              <!--                <svg class="icon" aria-hidden="true">-->
              <!--                  <use xlink:href="#icon-yidong"></use>-->
              <!--                </svg>-->
              <!--              </Tooltip>-->
              <span v-if="section.liveState === 0" @click="handleEditSection(chapter, section)">编辑</span>
              <span v-if="section.liveState === 1" @click="handleDVideo(section)">下载</span>
              <span v-if="section.type === 0 || section.type === 1" @click.stop="viewExercises(chapter, section)">查看</span>
              <span v-if="section.type === 0 || section.type === 1" @click.stop="modifyExercises(chapter, section)">编辑</span>
              <span @click.stop="handleDeleteSection(section, chapterIndex)">删除</span>
              <!--              `/manage/course/practice/newPaper/${courseId}`-->
              <!-- <Tooltip v-if="isOwner" placement="top" content="新增练习" STYLE="margin-left: 5px">
                  <div @click.stop="topot('section', section, chapter.name, chapter)" style="position: relative;top: 1px;left: 1px">
                    <img src="@assets/pot.png" height="12" width="12" />
                  </div>
                </Tooltip> -->
              <!--                <Tooltip placement="top" content="播放" style="margin-left: 5px">-->
              <!--                  <Icon type="ios-play-outline" @click="porVideo = true, getPorVideo(section)" />-->
              <!--                </Tooltip>-->
            </div>
          </div>
        </div>
        <!--        <transition-group name="fade" mode="out-in">-->
        <div class="sections" v-if="expand1 === section.sectionId" :key="section.sectionId">
          <div class="title" v-for="(inlist, index) in section.exercise" :key="index" @click="handleViewPractice(inlist, chapter)">
            <div class="name" style="flex: auto;padding-left: 52px">
              <img src="@/assets/193.png" height="12" width="10" />
              {{ inlist.name }}
            </div>
            <div style="width: 10%"></div>
            <div style="width: 10%"></div>
            <div style="width: 15%">练习次数{{ inlist.times }}</div>
            <div style="width: 15%">----</div>
            <div style="width: 10%">--</div>
            <div style="width: 10%;">
              <div class="chapter-opt">
                <div style="display: flex">
                  <Tooltip content="编辑" placement="top">
                    <svg class="icon" aria-hidden="true" @click="handleViewPractice(inlist, chapter, section)">
                      <use xlink:href="#icon-bianji" />
                    </svg>
                  </Tooltip>
                  <div @click.stop="handleDelete(inlist)">
                    <Tooltip placement="top" content="删除">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-shanchu" />
                      </svg>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--        </transition-group>-->
      </div>
      <!--      </transition-group>-->
    </div>
    <AddLiveSectionModal
      :is-add="isAddingSection"
      v-model="liveModalVisible"
      :section="chosenSection"
      :cover-type="coverType"
      :course-id="courseId"
      :chapter-id="chosenChapter ? chosenChapter.chapterId : -1"
      :sequence="sectionSequence"
      @on-add-success="getSectionsOfChapter"
    />
    <AddVideoSectionModal
      :is-add="isAddingSection"
      ref="AddVideoSectionModal"
      :section="chosenSection"
      :cover-type="coverType"
      :course-id="courseId"
      :chapter-id="chosenChapter ? chosenChapter.chapterId : -1"
      :sequence="sectionSequence"
      @on-add-success="getSectionsOfChapter"
    />
    <AddDatumSectionModal
      ref="AddDatumSectionModal"
      :is-add="isAddingSection"
      :section="chosenSection"
      :cover-type="coverType"
      :course-id="courseId"
      :folder-id="this.folderId"
      :chapter-id="chosenChapter ? chosenChapter.chapterId : -1"
      :sequence="sectionSequence"
      @on-add-success="getSectionsOfChapter"
    />

    <el-dialog :show-close="false" class="video-dialog" :visible.sync="porVideo" width="1024px" footer-hide @close="reset()" destroy-on-close>
      <div style="width: 100%;height: 100%">
        <div id="videoPlayer" style="width: 100%;height: 100%" />
      </div>
    </el-dialog>

    <el-dialog :visible="showAddStage" title="绑定阶段" class="yt-dialog yt-dialog-medium" @close="closeAddStage">
      <div class="addTarget">
        <div style="width: 50%;height: 100%;overflow: auto;border-right: 1px solid #E4E9EF;padding: 20px;">
          <div v-for="target in targetLists" :key="target.id" style="margin-bottom: 12px;cursor: pointer">
            <svg
              @click="getTartets(target)"
              class="icon"
              aria-hidden="true"
              :style="showIconId === target.id ? 'transform: rotate(0deg)' : 'transform: rotate(-90deg)'"
            >
              <use xlink:href="#icon-open" />
            </svg>
            <span style="font-size: 14px;font-weight: bold;color: #555555;margin-left: 8px" @click="getTartets(target)">{{ target.name }}</span>
            <div v-if="showIconId === target.id" v-for="stage in targetListes" :key="stage.id">
              <CheckboxGroup v-model="stageVal" style="margin-left: 20px;height: 30px;display: flex;align-items: center">
                <Checkbox :label="stage.id" style="font-size: 14px">{{ stage.name }}</Checkbox>
              </CheckboxGroup>
            </div>
          </div>
        </div>
        <div style="width: 50%;height: 100%;">
          <div class="top">
            <span>已绑定目标-阶段</span>
            <Button type="primary" @click="$refs.clearStargetDialog.visible = true">清空</Button>
          </div>
          <div style="height: calc(100% - 40px);overflow: auto">
            <div v-for="target in targetLists" :key="target.id" style="margin-left: 25px">
              <div v-for="stage in stageLists" :key="stage.id" style="width: 100%">
                <div
                  style="height: 30px;display: flex;align-items: center;width: 100%;color: #555555;font-size: 13px;font-weight: 700"
                  v-if="stage.targetId === target.id"
                >
                  {{ target.name }}
                </div>
                <div>
                  <div v-if="stage.targetId === target.id" @mouseenter="getStageId(stage)" @mouseleave="targetsEnterId = 0" style="display: flex">
                    <div style="width: 12px;cursor: pointer">
                      <svg v-if="targetsEnterId === stage.id" @click="delStage(stage)" class="icon" aria-hidden="true" font-size="8">
                        <use xlink:href="#icon-guanbi1" />
                      </svg>
                    </div>
                    <span style="color: #B0B0B0FF">{{ stage.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="footer">
        <el-button @click="closeAddStage" class="button">取消</el-button>
        <el-button type="primary" class="button" :loading="loading" @click="addChapterGradation">确定</el-button>
      </div>
    </el-dialog>
    <ConfirmDialog ref="deleteSectionDialog" :title="`确认要删除小节吗`" @on-ok="deleteSection">
      <div style="width: 238px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
        删除小节后不可恢复，你还要继续吗？
      </div>
    </ConfirmDialog>
    <ConfirmDialog ref="deleteChaptersDialog" :title="`确认删除练习`" @on-ok="deleteChapterPractice">
      <div style="width: 238px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
        删除章节练习后不可恢复，你还要继续吗？
      </div>
    </ConfirmDialog>
    <ConfirmDialog ref="deleteDialog" :title="`确认删除练习`" @on-ok="delprect">
      <div style="width: 238px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
        删除练习后不可恢复，你还要继续吗？
      </div>
    </ConfirmDialog>
    <ConfirmDialog ref="deleteChapterDialog" :title="`确认要删除章节吗`" @on-ok="deleteChapter">
      <div style="width: 238px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
        删除后章节下的所有内容会同时删除，你还要继续吗？
      </div>
    </ConfirmDialog>
    <ConfirmDialog ref="deleteStargetDialog" :title="`确认删除阶段吗`" @on-ok="delStages">
      <div style="width: 238px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
        删除阶段后不可恢复，你还要继续吗？
      </div>
    </ConfirmDialog>
    <ConfirmDialog ref="clearStargetDialog" :title="`确认清空阶段吗`" @on-ok="clearStages">
      <div style="width: 238px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
        删除练习后不可恢复，你还要继续吗？
      </div>
    </ConfirmDialog>
    <ConfirmDialog ref="phaseDialog" :title="`提示`" :buttonText="`绑定阶段`" @on-ok="addStage">
      <div style="width: 238px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
        当前章节未绑定阶段！
      </div>
      <p style="color: #666666;">
        随机练习需要将章节绑定阶段，请先给章节绑定阶段。
      </p>
    </ConfirmDialog>
    <AddPracticeDialog @addInfo="topot" ref="addPracticeDialog" />
  </div>
</template>
<script>
import courseApi from '@api/course'
import examApi from '@api/exam'
import AddLiveSectionModal from './AddLiveSectionModal'
import AddVideoSectionModal from './AddVideoSectionModal'
import AddDatumSectionModal from './AddDatumSectionModal'
import DeleteModal from '@components/common/DeleteModal'
import sectionTypes from '@util/sectionType'
import EditorVideoByLiveModal from '@components/common/edtiorVideoByLiveModal'
import YTIcon from '@components/common/YTIcon'
import ossApi from '@api/oss'
import Player from 'xgplayer'
import HlsJsPlayer from 'xgplayer-hls.js'
import AddPracticeDialog from '@components/common/dialog/AddPracticeDialog'
import practiceApi from '@api/practice'
import ConfirmDialog from '@components/common/dialog/ConfirmDialog'
export default {
  name: 'CourseCatFalog',
  components: {
    ConfirmDialog,
    YTIcon,
    EditorVideoByLiveModal,
    AddLiveSectionModal,
    AddVideoSectionModal,
    AddDatumSectionModal,
    DeleteModal,
    AddPracticeDialog
  },
  props: {
    isOwner: {
      type: Number
    },
    courseId: {
      type: Number,
      required: true
    },
    folderId: {
      type: Number,
      required: true
    },
    value: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  created() {
    this.getData()
  },
  mounted() {
    const _this = this
    setTimeout(() => {
      for (let i = 0; i < _this.chapters.length; i++) {
        if (_this.chapters[i].chapterId === _this.$route.query.itChapter) {
          _this.getdown(_this.chapters[i])
        }
      }
    }, 1000)
  },
  data() {
    return {
      typeIcon: ['', '#icon-zhibo', '#icon-bofang', '#icon-wenjian', '', '#icon-wodelianxi'],
      coverType: 1,
      chapterDate: null,
      chapterPracticesList: [],
      gradationList: [],
      chapterList: [],
      player: null,
      chaptersId: 0,
      practiceStatus: false,
      stageVal: [],
      showIcon: false,
      showAddStage: false,
      isShowOut: false,
      porVideo: false,
      deleteProtectModalVisible: false,
      isAddingChapter: false,
      isAddingSection: true,
      liveModalVisible: false,
      deleteLoading: false,
      loading: false,
      chapters: [],
      newChapterName: '',
      editChapterName: '',
      newSectionName: '',
      getList: [],
      chosenChapter: null,
      chosenSection: null,
      sectionChosen: null,
      itSection: null,
      addNameValid: true,
      editNameValid: true,
      practiceId: 0,
      targetListId: 0,
      targetLists: [],
      showIconId: 0,
      targetListes: [],
      stageLists: [],
      gradationId: 0,
      stageId: 0,
      itlist: [
        {
          name: ''
        }
      ],
      expand1: 0,
      targetsEnterId: 0,
      chapterIndex: 0
    }
  },
  methods: {
    //查看练习
    viewExercises(chapter, section) {
      this.$router.push({
        name: 'PracticeView',
        params: {
          chapter: chapter,
          section: section
        }
      })
    },
    modifyExercises(chapter, section) {
      if (section.type) {
        //修改随机练习
        this.$refs.addPracticeDialog.open(chapter, section)
      } else {
        //修改手动练习
        this.$router.push({ name: 'NewPractice', params: { chapter: chapter, section: section } })
      }
    },
    addPractice(data, status) {
      if (!status) {
        if (data.gradations) {
          //新增随机练习
          this.$refs.addPracticeDialog.open(data, null)
        } else {
          this.stageId = data.chapterId
          this.targetListId = data.courseId
          this.$refs.phaseDialog.open(data)
        }
      } else {
        //新增手动练习
        this.$router.push({ name: 'NewPractice', params: { chapter: data } })
      }
    },
    reset() {
      this.player = null
      this.porVideo = false
    },
    getData() {
      this.getCourseDetail()
      this.getTarget()
      ossApi.upType().then(type => {
        this.coverType = type.res
      })
    },
    sortSize(val) {
      if (val === 0) return val + ' ' + 'KB'
      if (!val) return '/'
      if (val < 1024) return val + 'B'
      val = val / 1024
      if (val / 1024 < 1) return val + 'KB'
      if (val / 1048576 >= 1) return (val / 1048576).toFixed(2) + 'GB'
      if (val / 1024 >= 1 && val / 1024 < 1024) return (val / 1024).toFixed(2) + 'MB'
    },
    handleEditSection(chapter, section) {
      this.chosenChapter = chapter
      this.chosenSection = section
      this.isAddingSection = false
      if (section.liveState === 1) {
        // 直播已结束,可以编辑视频
        section.type = 2
        // return this.edtiorLiveVideo(chapter, section)
      }
      this.handleShowSectionModal(String(section.type))
    },
    // getChapterPractices(chapterId) {
    //   courseApi.getPractisesByChapterId(chapterId).then(res => {
    //     if (res.res.length > 0) {
    //       console.log(res.res)
    //       this.chapterPracticesList = res.res
    //     } else {
    //       this.chapterPracticesList = []
    //     }
    //   })
    // },
    message(msg, type) {
      this.$message({ message: msg, type: type })
    },
    editorP(chapter, id) {
      if (!this.isOwner) {
        return
      }
      // id => practiseId
      examApi.getpracticeById(id).then(res => {
        if (!res.res.practiceId) {
          this.message('该练习不存在或已被删除', 'warning')
        } else {
          this.$router.push({
            path: `/manage/study/course/practice`,
            query: {
              id: id,
              courseId: this.courseId,
              isChapter: false,
              isEditor: true,
              priTitle: res.res.name,
              chapters: chapter.chapterId,
              chapter: {
                secName: '',
                chaName: chapter.name
              }
            }
          })
        }
      })
    },
    deleteChapterPractice(practiceId) {
      courseApi.deletePractisesByChapterId(this.chapterDate.chapterId, [this.chapterDate.practiceId]).then(res => {
        if (res.res) {
          this.$message.success('删除成功')
        }
        this.getCourseDetail()
      })
    },
    getCourseDetail() {
      this.loading = true
      courseApi
        .getCourseDetail(this.courseId)
        .then(res => {
          let row = []
          res.res.chapters.map(item => {
            row.push(item)
            if (item.gradations !== undefined) {
              this.chapterList = item.gradations
            }
            this.chaptersId = item.chapterId
          })
          this.chapters = res.res.chapters
          this.gradationList = row
        })
        .finally(() => {
          this.loading = false
        })
    },
    clearStages() {
      courseApi.clearChapterGradation(this.stageId).then(res => {
        this.$message.success('阶段已清空')
        this.getCourseDetail()
        this.getAddStage()
      })
    },
    delStages() {
      courseApi.delChapterGradation(this.stageId, this.gradationId).then(res => {
        this.$message.success('删除成功')
        this.gradationId = 0
        this.getCourseDetail()
        this.getAddStage()
      })
    },
    getStageId(data) {
      this.targetsEnterId = data.id
    },
    delStage(data) {
      this.gradationId = data.id
      this.$refs.deleteStargetDialog.visible = true
    },
    addChapterGradation() {
      if (this.stageVal.length === 0) {
        this.showAddStage = false
      } else {
        let payload = {
          chapterId: this.stageId,
          gradationIds: this.stageVal
        }
        courseApi.addChapterGradation(payload).then(res => {
          this.$message.success('阶段添加成功')
          this.stageVal = []
          this.showAddStage = false
          this.getCourseDetail()
        })
      }
    },
    getTartets(data) {
      if (this.showIconId === data.id) {
        this.showIconId = 0
      } else {
        this.showIconId = data.id
      }
      this.targetListes = []
      this.getStage()
    },
    addStage(data) {
      if (data) {
        this.stageId = data.chapterId
        this.targetListId = data.courseId
      }
      this.showAddStage = true
      this.getTarget()
      this.getAddStage()
    },
    getAddStage() {
      courseApi.getGradationAll(this.stageId).then(res => {
        if (res.code === 0) {
          this.stageLists = res.res
        }
      })
    },
    getTarget() {
      courseApi.getCourseTarget(this.courseId).then(res => {
        if (res.code === 0) {
          this.targetLists = res.res
          if (!res.res.length && this.showAddStage) {
            this.$message.warning('请先绑定课程目标')
          }
        }
      })
    },
    getStage() {
      courseApi.targetGradation(this.showIconId).then(res => {
        this.targetListes = res.res
      })
    },
    closeAddStage() {
      this.stageVal = []
      this.showAddStage = false
    },
    handleClickIcon(section) {
      if (section.type === 2) {
        this.porVideo = true
        this.getPorVideo(section)
      }
    },
    getPorVideo(section) {
      // 1 直播 2 视频  3文档
      switch (section.type) {
        case 1:
          this.$router.push({
            path: '/live',
            query: {
              courseId: this.courseId,
              sectionId: section.sectionId,
              sequence: section.sequence,
              targetId: section.targetId,
              chapterId: section.chapterId
            }
          })
          break
        case 2:
          if (section.videoUrl && section.videoUrl.match(/\.(avi|mp4)$/)) {
            let url = section.videoUrl
            setTimeout(() => {
              if (url.substr(url.lastIndexOf('.'), url.length) !== '.m3u8') {
                this.player = new Player({
                  id: 'videoPlayer',
                  url: section.videoUrl,
                  preloadTime: 15,
                  lang: 'zh-cn',
                  poster: section.cover ? section.cover : '',
                  videoInit: !!section.cover, // 封面首帧画面
                  fluid: true //设置为流式布局，可使播放器宽度跟随父元素的宽度大小变化
                })
              } else {
                this.player = new HlsJsPlayer({
                  id: 'videoPlayer',
                  url: section.videoUrl,
                  preloadTime: 15,
                  lang: 'zh-cn',
                  poster: section.cover ? section.cover : '',
                  videoInit: !!section.cover, // 封面首帧画面
                  // isLive: true,
                  cors: true,
                  fluid: true //设置为流式布局，可使播放器宽度跟随父元素的宽度大小变化
                })
              }
            }, 1000)
          } else {
            window.open(section.videoUrl, '_blank')
          }
          break
        case 3:
          this.porVideo = false
          courseApi.getChapterFile(section.sectionId).then(res => {
            location.href = res.res.datumUrl
          })
          break
      }
      this.porVideo = true
    },
    topot(isChapter, section, chapter) {
      if (isChapter === 'chapter') {
        this.$router.push({
          path: `/manage/study/course/practice`,
          query: {
            courseId: this.courseId,
            isChapter: '1',
            chapters: chapter.chapterId,
            chapter: {
              chaName: chapter.name
            }
          }
        })
      } else if (isChapter === 'section') {
        this.$router.push({
          path: `/manage/study/course/practice`,
          query: {
            courseId: this.courseId,
            isChapter: '0',
            chapters: chapter.chapterId,
            chapter: {
              secName: section.name,
              secChaper: section.sectionId,
              chaName: chapter.name
            }
          }
        })
      }
    },
    handleViewPractice(inlist, chapter, section) {
      examApi.getpracticeById(inlist.practiceId).then(res => {
        if (!res.res.practiceId) {
          this.message('该练习不存在或已被删除', 'warning')
        } else {
          this.$router.push({
            path: `/manage/study/course/practice`,
            query: {
              id: inlist.practiceId,
              courseId: this.courseId,
              isChapter: false,
              isEditor: true,
              priTitle: res.res.name,
              chapters: chapter.chapterId,
              chapter: {
                secName: section.name,
                secChaper: section.sectionId,
                chaName: chapter.name
              }
            }
          })
        }
      })
    },
    delprect() {
      practiceApi.deletePractice(this.itSection.practiceId, this.itSection.sectionId).then(res => {
        if (res.code === 0) {
          const index = this.chapters[this.chapterIndex].sections.findIndex(data => data.practiceId === this.itSection.practiceId)
          this.chapters[this.chapterIndex].sections.splice(index, 1)
          this.$message.success('删除成功')
        }
      })
    },
    getPractice(chapter) {
      practiceApi.getAllPractice(chapter.chapterId).then(res => {
        if (res.code === 0) {
          for (const section of res.res.sectionPracticeList) {
            for (const sectionElement of section.sectionPracticeBasics) {
              chapter.sections.push({
                sectionId: section.sectionId,
                sectionNum: section.sequence,
                practiceId: sectionElement.practiceId,
                name: sectionElement.practiceName,
                times: sectionElement.count || 0,
                type: sectionElement.type,
                createdTime: sectionElement.createdTime,
                creatorName: sectionElement.creator
              })
            }
          }
          for (const practice of res.res.chapterPracticeList) {
            chapter.sections.push({
              chapterId: practice.chapterId,
              practiceId: practice.practiceId,
              practiceName: practice.practiceName,
              times: practice.count || 0,
              type: practice.type,
              createdTime: practice.createdTime,
              creatorName: practice.creator
            })
          }
        }
      })
    },
    getdown(chapter, index) {
      this.$set(chapter, 'expand', !chapter.expand)
      if (!chapter.expand) {
        this.itlist = []
      }
      courseApi.getCourseDetail(this.courseId).then(res => {
        res.res.chapters.map(item => {
          if (item.gradations !== undefined) {
            this.chapterList = item.gradations
          }
          this.chaptersId = item.chapterId
        })
        chapter.sections = res.res.chapters[index].sections
        if (chapter.expand) {
          this.getPractice(chapter)
        }
      })
    },
    getdown1(section) {
      if (this.expand1 === section.sectionId) {
        this.expand1 = 0
      } else {
        this.expand1 = section.sectionId
      }
    },
    getCourse(section) {
      courseApi.getCoursePracticeList(this.courseId).then(res => {
        section.exercise = []
        if (res.res.data !== undefined) {
          res.res.data.map(item => {
            if (section.name === item.section) {
              section.exercise.push(item)
            }
          })
        }
      })
    },
    addChapter() {
      if (!this.addNameValid) {
        this.$message.error('课程章节名称不超过80个字')
        return
      } else if (this.addNameValid === '') {
        this.$message.error('课程章节名称不能为空')
      }

      const chapter = {
        courseId: this.courseId,
        name: this.newChapterName.trim(),
        sequence: this.chapters.length + 1
      }
      courseApi.addChapter(chapter).then(res => {
        this.getCourseDetail()
        this.getTarget()
        chapter.chapterId = res.res
        chapter.expand = false
        chapter.isEdit = false
        // chapter.sectionsthis.addNameValid === ''
        // this.$emit('input', this.chapters)
        this.isAddingChapter = false
        this.newChapterName = ''
      })
    },
    updateChapter() {
      if (!this.editNameValid) {
        this.$message.error('章节名称不超过80个字')
        return
      } else if (this.editNameValid === '') {
        this.$message.error('章节名称不能为空')
      }

      const chapter = {
        chapterId: this.chosenChapter.chapterId,
        courseId: this.courseId,
        name: this.editChapterName.trim(),
        sequence: this.chosenChapter.sequence
      }

      courseApi.updateChapter(chapter).then(() => {
        this.chosenChapter.name = this.editChapterName
        this.getCourseDetail()
        this.$set(this.chosenChapter, 'isEdit', false)
      })
    },
    deleteChapter() {
      this.deleteLoading = true

      courseApi
        .deleteChapter(this.chosenChapter.chapterId)
        .then(() => {
          this.chapters.splice(
            this.chapters.findIndex(chapter => chapter.chapterId === this.chosenChapter.chapterId),
            1
          )
          this.getCourseDetail()
        })
        .finally(() => {
          this.deleteLoading = false
        })
    },
    deleteSection() {
      this.deleteLoading = true

      courseApi
        .deleteSection({
          sectionId: this.chosenSection.sectionId,
          type: this.chosenSection.type
        })
        .then(() => {
          let chosenChapter = this.chapters.find(chapter => chapter.chapterId === this.chosenSection.chapterId)
          chosenChapter.sections.splice(
            chosenChapter.sections.findIndex(section => section.sectionId === this.chosenSection.sectionId),
            1
          )
          this.getCourseDetail()
        })
        .finally(() => {
          this.deleteLoading = false
        })
    },
    getSectionsOfChapter() {
      this.$Spin.show()
      courseApi
        .getCourseDetail(this.courseId)
        .then(res => {
          let row = []
          res.res.chapters.map(item => {
            row.push(item)
            if (item.gradations !== undefined) {
              this.chapterList = item.gradations
            }
            this.chaptersId = item.chapterId
          })
          this.chapters = res.res.chapters
          this.gradationList = row
        })
        .finally(() => {
          this.$Spin.hide()
        })
      // courseApi
      //   .getSectionsOfChapter(this.chosenChapter.chapterId)
      //   .then(res => {
      //     this.chosenChapter.sections = res.res.sections
      //   })
      //   .finally(() => {
      //     this.$Spin.hide()
      //   })
    },
    //添加不同类型的小节
    handleClickAddSection(name, chapter) {
      this.isAddingSection = true
      this.chosenChapter = chapter
      if (name === sectionTypes.DATUM.value) {
        this.chosenSection = null
      }
      this.handleShowSectionModal(name)
    },
    handleEditChapter(chapter) {
      this.$set(chapter, 'isEdit', true)
      this.chosenChapter = chapter
      this.editChapterName = chapter.name
    },
    handleDVideo(section) {
      // 视频下载
      let _this = this
      _this.openDownModal = true
      if (section.videoUrl === '' || !section.videoUrl) return this.$message.warning('无视频流...')
      var xhr = new XMLHttpRequest()
      xhr.open('GET', section.videoUrl, true)
      xhr.responseType = 'blob' // 返回类型blob
      xhr.onload = function() {
        if (xhr.readyState === 4 && xhr.status === 200) {
          let blob = this.response
          // 转换一个blob链接
          let u = window.URL.createObjectURL(new Blob([blob]))
          let a = document.createElement('a')
          a.download = `${section.name}.mp4` // 注意给文件加个后缀
          a.href = u
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          a.remove()
        }
      }
      xhr.send()
    },
    handleShowSectionModal(type) {
      switch (type) {
        // case sectionTypes.LIVE.value:
        //   this.liveModalVisible = true
        //   break
        case sectionTypes.VIDEO.value:
          this.$refs.AddVideoSectionModal.open(this.chosenSection)
          break
        case sectionTypes.DATUM.value:
          this.$refs.AddDatumSectionModal.open(this.chosenSection)
          break
        default:
          this.$message.error('错误小节类型')
      }
    },
    handleDeleteChapter(chapter) {
      this.chosenChapter = chapter
      this.$refs.deleteChapterDialog.visible = true
    },
    handleDeleteSection(section, index) {
      if (section.practiceName) {
        this.$refs.deleteChaptersDialog.visible = true
        this.chapterDate = section
      } else if (section.sectionNum) {
        this.$refs.deleteDialog.visible = true
        this.itSection = section
        this.chapterIndex = index
      } else {
        this.$refs.deleteSectionDialog.visible = true
        this.chosenSection = section
      }
    },
    handleDelete(section) {
      this.itSection = section
      this.$refs.deleteDialog.visible = true
    },
    handleCheckAddChapterName(event) {
      this.addNameValid = event.trim().length <= 80
    },
    handleCheckEditChapterName(event) {
      this.editNameValid = event.target.value.trim().length <= 80
    },
    getIconBySectionType(type) {
      for (const sectionType in sectionTypes) {
        if (sectionTypes[sectionType].value === String(type)) {
          return sectionTypes[sectionType].icon
        }
      }
    }
  },
  watch: {
    value(val) {
      this.chapters = val
    }
    // chapters: {
    //   handler(val) {
    //     this.chapters = val
    //   },
    //   deep: true
    // }
  },
  computed: {
    sectionTypes() {
      return sectionTypes
    },
    sectionSequence() {
      return this.isAddingSection ? (this.chosenChapter ? this.chosenChapter.sections.length + 1 : 1) : this.chosenSection.sequence
    }
  }
}
</script>
<style lang="less" scoped>
@import '~@/theme/tableDropdownMenu';
@import '~@/theme/variables';
.ivu-select-dropdown .ivu-dropdown-menu {
  width: 170px;
}
.el-dropdown-menu {
  width: 100px;
  .el-dropdown-menu__item {
    padding: 0;
    p {
      padding: 0 20px;
    }
  }
}
.head {
  display: flex;
  background: rgba(248, 249, 251, 1);
  height: 55px;
  border-bottom: 1px solid rgba(234, 237, 241, 1);
  color: #3a4e64;
  font-size: 16px;
  font-weight: 400;
  align-items: center;
  padding: 0 20px;
}
.section-container {
  min-height: 300px;
  .head {
    font-size: 12px;
    font-weight: bold;
    line-height: 17px;
    color: #000000;
  }
  ::v-deep .error input {
    border: 1px solid #e52436;

    &:focus {
      box-shadow: 0 0 0 2px rgba(229, 36, 54, 0.2);
    }
  }

  .chapter {
    font-size: 12px;
    color: @font-color-content;

    .title {
      height: 50px;
      align-items: center;
      padding: 0 24px;
      border-bottom: 1px solid #eaedf1;
      transition: all @default-transition;
      display: flex;
      color: #000000;
      &:after {
        content: '';
        display: inline-block;
        clear: both;
      }

      .arrow {
        margin-right: 4px;
        cursor: pointer;
        transform: rotate(-90deg);
        transition: all @default-transition;
        fill: @font-color-content;

        &.spread {
          transform: rotate(0);
        }
      }

      .name {
        float: left;
        cursor: pointer;
      }

      .time {
        float: left;
      }

      .chapter-opt {
        .el-dropdown {
          margin-left: 12px;
        }
        span,
        p {
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
          color: #438bff;
          cursor: pointer;
        }
        span {
          margin-right: 12px;
        }

        svg {
          margin: 0 6px;
          cursor: pointer;
          color: #2d8cf0;
          transition: all @default-transition;

          &:hover {
            color: @primary-color;
          }
        }
      }

      // &:hover {
      //   background-color: #f8f9fb;

      //   .chapter-opt {
      //     display: block;
      //   }
      // }
    }
  }

  ::v-deep .chapter-add {
    text-align: center;
    padding: 8px 0;
    margin-top: 10px;
    cursor: pointer;
    font-size: 12px;
    color: #999999;
    transition: color @default-transition;
    border: 1px solid #d9d9d9;
    border-style: dashed;
    border-radius: 4px;
    .icon {
      color: #999999;
    }
  }

  ::v-deep .ivu-input-suffix {
    width: auto;
  }

  ::v-deep .ivu-input-with-suffix {
    padding-right: 56px;
  }
}
::v-deep .but {
  background-color: #f8f9fb;
  border: none;
}
::v-deep .ivu-btn-primary:hover {
  background-color: #f8f9fb;
}
::v-deep .vjs-custom-skin > .video-js .vjs-big-play-button {
  width: 72px;
  border-radius: 50% !important;
}
::v-deep .vjs-icon-placeholder:before {
  margin-top: -2px;
}
.practisesInfo {
  margin-left: 20px;
  color: #2d8cf0;
}
.x-img {
  float: right;
  bottom: 5px;
  cursor: pointer;
  margin: -30px -30px 0 0;
}
.addTarget {
  height: 393px;
  border: 1px solid #e4e9ef;
  display: flex;
}
.top {
  color: #747475;
  font-size: 14px;
  padding: 20px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}
::-webkit-scrollbar {
  width: 6px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 0, 0, 0.4);
}
.video-dialog {
  ::v-deep {
    .el-dialog {
      background-color: transparent;
      border: 0;
      box-shadow: none;
    }
  }
}
.section-name {
  margin-left: 5px;
  width: 250px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
